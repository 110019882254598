import React, { useCallback, useEffect } from 'react'
import { Router } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { setAuthToken, setCurrentUser } from 'src/utils/reduxUtils'
import {
  fetchAreas,
  fetchBranches,
  fetchBrandApprovedStaffList,
  fetchRegions,
} from 'src/API'
import routes from 'src/routes'
import history from 'src/utils/history'
import MomentUtils from '@date-io/moment'
import GoogleAnalytics from './components/Singles/GoogleAnalytics'
import ScrollReset from './components/Singles/ScrollReset'
import { theme } from './theme'
import { getAccessToken, getCurrentUser } from './utils/auth'
import {
  fetchApprovedStaffAction,
  fetchAreasAction,
  fetchBranchesAction,
  fetchRegionsAction,
} from './store/actions'
import './assets/scss/main.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'

function App({ brandId, userRole }) {
  const dispatch = useDispatch()
  const fetchBrandData = useCallback(async () => {
    const [
      { data: branches },
      { data: regions },
      { data: areas },
      { data: staff },
    ] = await Promise.all([
      fetchBranches(),
      fetchRegions(),
      fetchAreas(),
      fetchBrandApprovedStaffList(),
    ])

    dispatch(fetchBranchesAction(branches))
    dispatch(fetchRegionsAction(regions))
    dispatch(fetchAreasAction(areas))
    dispatch(fetchApprovedStaffAction(staff))
  }, [dispatch])

  useEffect(() => {
    if (brandId && userRole !== 'default') {
      fetchBrandData()
    }
  }, [brandId, fetchBrandData, userRole])

  const user = getCurrentUser()

  function authUser() {
    // in case of deactivate login at dashboard and depend on userapp authentication
    // const user = getCookie('user')
    // user ? localStorage.setItem('user', user) : localStorage.clear()
    // localStorage.setItem('user', user)
    if (localStorage.user) {
      setAuthToken(getAccessToken())
      dispatch(setCurrentUser(user))
    }
  }

  authUser()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Helmet>
          <title>Surv</title>
          <meta name="description" content="Surv Analytics Dashboards & Profiles" />
        </Helmet>
        <Router history={history}>
          <ScrollReset />
          <GoogleAnalytics />
          {renderRoutes(routes)}
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = ({ session }) => ({
  brandId: session.user.brandId,
  userRole: session.user.role,
})

export default connect(mapStateToProps, null)(App)
