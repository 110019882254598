import React from 'react'

export const FullStar = ({ score }) => {
  return (
    <>
      {score === 1 && (
        <svg
          width="50"
          height="50"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.17"
            width="40.3846"
            height="40.3846"
            rx="7"
            fill="#0052D4"
          />
          <path
            d="M12.3188 26.9949L16.087 29.8061L19.9517 24.6684L23.913 30L27.7778 27.1888L23.913 21.8571L30 19.9184L28.6473 15.4592L22.4638 17.4949V11H17.6329V17.4949L11.5459 15.2653L10 19.7245L16.2802 21.8571L12.3188 26.9949Z"
            fill="#0052D4"
          />
        </svg>
      )}
      {score === 0.5 && (
        <svg
          width="50"
          height="50"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.17"
            x="0.461548"
            width="40.3846"
            height="40.3846"
            rx="7"
            fill="#C2C6D2"
          />
          <path
            opacity="0.17"
            d="M0.461548 7C0.461548 3.134 3.59556 0 7.46155 0H21.2308V40.3846H7.46155C3.59556 40.3846 0.461548 37.2506 0.461548 33.3846V7Z"
            fill="#0052D4"
          />
          <path
            d="M13.3188 26.9949L17.087 29.8061L20.9517 24.6684L24.913 30L28.7778 27.1888L24.913 21.8571L31 19.9184L29.6473 15.4592L23.4638 17.4949V11H18.6329V17.4949L12.5459 15.2653L11 19.7245L17.2802 21.8571L13.3188 26.9949Z"
            fill="#0052D4"
          />
          <path
            d="M20.9517 24.6684L24.913 30L28.7778 27.1888L24.913 21.8571L31 19.9184L29.6473 15.4592L23.4638 17.4949V11H20.9517V17.4949L20.9517 21L20.9517 23L20.9517 24.6684Z"
            fill="#707070"
          />
        </svg>
      )}
      {score === 0 && (
        <svg
          width="50"
          height="50"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.17"
            x="0.615417"
            width="40.3846"
            height="40.3846"
            rx="7"
            fill="#C2C6D2"
          />
          <path
            d="M13.3188 26.9949L17.087 29.8061L20.9517 24.6684L24.913 30L28.7778 27.1888L24.913 21.8571L31 19.9184L29.6473 15.4592L23.4638 17.4949V11H18.6329V17.4949L12.5459 15.2653L11 19.7245L17.2802 21.8571L13.3188 26.9949Z"
            fill="#707070"
          />
        </svg>
      )}
    </>
  )
}
