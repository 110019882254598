import { SET_PENDING } from '../actions/types'

const INITIAL_STATE = { create: false, fetch: false, delete: false, count: 0 }

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_PENDING:
      return { ...state, ...payload }
    default:
      return state
  }
}
