import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    borderRadius: '7px',
    color: '#79869F',
    fontSize: '16px',
    border: '1px solid #79869F',
    '&:hover': {
      border: '1px solid black',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '&.Mui-focused': {
      border: '1px solid #5850EC',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputAdornment-root': {
      backgroundColor: '#F7F7FC',
      padding: '28px 35px',
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px',
      marginRight: -14,
      borderLeft: '1px solid #9A9AB0;',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '10px',
  },
})

export const TagsField = ({ ...props }) => {
  const classes = useStyles()
  const [value, setValue] = useState()
  const { values, setFieldValue } = useFormikContext()
  const [field, { touched, error }] = useField(props)
  const {
    titleClass,
    title,
    fieldClass,
    InputProps,
    data,
    optionLabel,
    optionValue,
    name,
  } = props

  return (
    <>
      {title && (
        <Typography className={titleClass || classes.title}>{title}</Typography>
      )}
      <Autocomplete
        {...field}
        {...props}
        getOptionLabel={(option) => {
          const label = optionLabel ? option[optionLabel] : option
          return label || ''
        }}
        InputProps={{ root: classes.root, ...InputProps }}
        error={touched && error ? error : null}
        helperText={touched && error}
        defaultValue={values?.tags || ''}
        onChange={(e, data) => {
          setFieldValue(name, data ? data[optionValue] : '')
          setValue(data)
        }}
        value={value}
        options={data || []}
        classes={{ inputRoot: fieldClass || classes.root }}
        renderInput={(params) => (
          <TextField
            {...field}
            {...props}
            {...params}
            // InputProps={{ ...InputProps, className: classes.root }}
            error={touched && error ? error : null}
            helperText={touched && error}
          />
        )}
      />
    </>
  )
}
