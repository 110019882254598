import {
  ADD_REGION,
  UPDATE_REGION,
  DELETE_REGION,
  FETCH_REGIONS,
  FETCH_REGION,
  SELECT_REGION,
  VIEW_REGION_AREAS,
  VIEW_REGION_BRANCHES,
} from './types'

export const addRegionAction = (payload) => (dispatch) => {
  dispatch({ type: ADD_REGION, payload: payload })
}

export const updateRegionAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_REGION, payload: payload })
}

export const deleteRegionAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_REGION, payload: payload })
}

export const fetchRegionsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_REGIONS, payload: data })
}

export const getRegionAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_REGION, payload: data })
}
export const selectRegionAction = (region) => (dispatch) => {
  dispatch({ type: SELECT_REGION, payload: region })
}

export const viewRegionAreasAction = (regionAreas) => (dispatch) => {
  dispatch({ type: VIEW_REGION_AREAS, payload: regionAreas })
}
export const viewRegionBranchesAction = (regionBranches) => (dispatch) => {
  dispatch({ type: VIEW_REGION_BRANCHES, payload: regionBranches })
}
