/* eslint-disable no-restricted-globals */
import history from 'src/utils/history'
import { setAuthToken } from 'src/utils/reduxUtils'
import { welcomeRoute } from 'src/constants'
import {
  FETCH_STAFF_DETAILS,
  REFRESH_TOKEN,
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SESSION_UPDATED,
} from './types'

export const loginAction = (user) => (dispatch) => {
  dispatch({ type: SESSION_LOGIN, payload: user })
  dispatch(setStaffDetailsAction())
}

export const registerAction = (user) => (dispatch) => {
  dispatch({ type: SESSION_LOGIN, payload: user })
  history.push(welcomeRoute)
}

export const loginByFacebookAction = (data) => (dispatch) => {
  dispatch({ type: SESSION_LOGIN, payload: data })
  history.push(welcomeRoute)
}
export const loginByGoogleAction = (data) => (dispatch) => {
  dispatch({ type: SESSION_LOGIN, payload: data })
  history.push(welcomeRoute)
}

export const logoutUser = () => (dispatch) => {
  localStorage.clear()
  setAuthToken(false)
  dispatch({
    type: SESSION_LOGOUT,
  })
}

export const updateSession = (data) => (dispatch) => {
  dispatch({
    type: SESSION_UPDATED,
    payload: data,
  })
}

export const refreshTokenAction = (access) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: access,
  })
}

export const setStaffDetailsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_STAFF_DETAILS, payload: data })
}
