import { BASE_URL } from 'src/Config'
import axiosInstance from './axios'

export const fetchReviews = (brandId, page, branchId, filters) => {
  const { status, user, complaint } = filters
  let surveyEngine = ''
  let filterParams = ''
  if (status === 'current' || !status) surveyEngine = 'surveys-v2'
  else if (status === 'archived') surveyEngine = 'surveys'

  if (user) filterParams = `target_user=${user}&`
  if (complaint) filterParams = `${filterParams}has_complaint=${complaint}&`
  if (branchId !== 'undefined') filterParams = `${filterParams}branch=${branchId}`

  const url = `${BASE_URL}/api/dashboards/${surveyEngine}/${brandId}/responses/?page=${page}&${filterParams}`
  return axiosInstance.get(url)
}

export const retrieveReview = (id, status, brandId, location) => {
  const type = status === 'archived' ? 'surveys' : 'surveys-v2'
  const url = `${BASE_URL}/api/dashboards/${type}/${brandId}/responses/${id}/?branch=${location}`
  return axiosInstance.get(url)
}
