import {
  ADD_AREA,
  UPDATE_AREA,
  DELETE_AREA,
  FETCH_AREAS,
  FETCH_AREA,
  SELECT_AREA,
  VIEW_AREA_BRANCHES,
  FETCH_AREA_PERFORMANCE_TREND,
  FETCH_AREA_RESPONSE_TREND,
  FETCH_AREA_NPS_TREND,
  FETCH_AREA_BRANCHES_PERFORMANCE,
  FETCH_AREA_STAFF_PERFORMANCE,
} from './types'

export const addAreaAction = (payload) => (dispatch) => {
  dispatch({ type: ADD_AREA, payload: payload })
}

export const updateAreaAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_AREA, payload: payload })
}

export const deleteAreaAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_AREA, payload: payload })
}

export const fetchAreasAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREAS, payload: data })
}
export const fetchAreaPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA_PERFORMANCE_TREND, payload: data })
}
export const fetchAreaBranchesPerformanceAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA_BRANCHES_PERFORMANCE, payload: data })
}
export const fetchAreaStaffPerformanceAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA_STAFF_PERFORMANCE, payload: data })
}
export const fetchAreaResponseTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA_RESPONSE_TREND, payload: data })
}
export const fetchAreaNPSTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA_NPS_TREND, payload: data })
}
export const getAreaAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_AREA, payload: data })
}

export const selectAreaAction = (area) => (dispatch) => {
  dispatch({ type: SELECT_AREA, payload: area })
}
export const viewAreaBranchesAction = (areaBranches) => (dispatch) => {
  dispatch({ type: VIEW_AREA_BRANCHES, payload: areaBranches })
}
