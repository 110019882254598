import { BASE_URL } from 'src/Config'
import { getCurrentBrandId } from 'src/utils/auth'
import axiosInstance from './axios'

export const fetchQrTemplates = () => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}qr-templates/`)
}

export const fetchQrTemplate = (templateId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}qr-templates/${templateId}/`
  )
}

export const addQrTemplate = (qrTemplate) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}qr-templates/`, {
    brand: brand,
    ...qrTemplate,
  })
}

export const updateQrTemplate = (templateId, template) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/${brand}qr-templates/${templateId}/`,
    { brand: brand, ...template }
  )
}

export const deleteQrTemplate = (templateId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${brand}qr-templates/${templateId}/`
  )
}
