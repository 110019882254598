import React from 'react'

export const BranchIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.85 4.00556L13.95 1.32222C13.6875 0.544444 12.975 0 12.1875 0H2.8125C2.025 0 1.3125 0.544444 1.05 1.32222L0.15 4.00556C-0.075 4.70556 5.58794e-09 5.44444 0.375 5.98889C0.6 6.3 0.9 6.57222 1.275 6.68889V11.6667C1.275 12.95 2.2875 14 3.525 14H11.55C12.7875 14 13.8 12.95 13.8 11.6667V6.68889C14.175 6.53333 14.475 6.3 14.7 5.98889C15.0375 5.40556 15.1125 4.70556 14.85 4.00556ZM6.675 12.4444V10.1889C6.675 9.76111 7.0125 9.37222 7.4625 9.37222H7.6125C8.025 9.37222 8.4 9.72222 8.4 10.1889V12.4444H6.675ZM12.2625 11.6667C12.2625 12.0944 11.925 12.4444 11.5125 12.4444H9.8625V10.1889C9.8625 8.86667 8.85 7.81667 7.575 7.81667H7.425C6.15 7.81667 5.1375 8.86667 5.1375 10.1889V12.4444H3.4875C3.075 12.4444 2.7375 12.0944 2.7375 11.6667V6.80556C3.1875 6.72778 3.6375 6.49444 4.0125 6.18333C4.4625 6.61111 5.0625 6.84444 5.7 6.84444C6.375 6.84444 6.975 6.61111 7.4625 6.18333C7.95 6.61111 8.5875 6.84444 9.225 6.84444C9.9 6.84444 10.5 6.61111 10.9125 6.18333C11.2875 6.49444 11.7375 6.68889 12.1875 6.80556V11.6667H12.2625Z"
        fill="#BCC3CF"
      />
    </svg>
  )
}
