import {
  GET_BRAND_DASHBOARD,
  FETCH_COMPLAINTS_ANALYTICS,
  FETCH_BRAND_PERFORMANCE_TREND,
  FETCH_BRAND_RESPONSE_TREND,
  FETCH_BRAND_NPS_TREND,
  FETCH_BRAND_AREAS_PERFORMANCE,
  FETCH_BRAND_REGIONS_PERFORMANCE,
  FETCH_BRAND_BRANCHES_PERFORMANCE,
  FETCH_BRAND_STAFF_PERFORMANCE,
} from '../actions/types'

const INITIAL_STATE = {
  score: -1,
  customer_satisfaction: null,
  nps_score: null,
  complaints: {
    total: 0,
    resolved: 0,
    unresolved: 0,
    satisfied: 0,
    unsatisfied: 0,
    average_handling_time: '00:00:00',
  },
  performanceTrend: [],
  responseTrend: [],
  npsTrend: [],
  regionsPerformance: [],
  areasPerformance: [],
  branchesPerformance: [],
  staffPerformance: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_BRAND_DASHBOARD:
      return { ...state, ...payload }
    case FETCH_BRAND_PERFORMANCE_TREND:
      return { ...state, performanceTrend: payload }
    case FETCH_BRAND_RESPONSE_TREND:
      return { ...state, responseTrend: payload }
    case FETCH_BRAND_NPS_TREND:
      return { ...state, npsTrend: payload }
    case FETCH_COMPLAINTS_ANALYTICS:
      return { ...state, complaints: payload }
    case FETCH_BRAND_REGIONS_PERFORMANCE:
      return { ...state, regionsPerformance: payload }
    case FETCH_BRAND_AREAS_PERFORMANCE:
      return { ...state, areasPerformance: payload }
    case FETCH_BRAND_BRANCHES_PERFORMANCE:
      return { ...state, branchesPerformance: payload }
    case FETCH_BRAND_STAFF_PERFORMANCE:
      return { ...state, staffPerformance: payload }
    default:
      return state
  }
}
