import { BASE_URL } from 'src/Config'
import { getCurrentBrandId } from 'src/utils/auth'
import axiosInstance from './axios'

export const fetchShortenedURLs = (pageNumber = 1, limit = 10) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/urlshortener/${brandId}/?page=${pageNumber}&page_size=${limit}`
  )
}

export const fetchShortenedURL = (urlID) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/urlshortener/${brandId}/urls/${urlID}`
  )
}

export const shortenURL = (url) => {
  const brandId = getCurrentBrandId()

  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/urlshortener/${brandId}/`,
    url
  )
}

export const updateShortenedURL = (urlID, url) => {
  const brandId = getCurrentBrandId()

  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/urlshortener/${brandId}/urls/${urlID}/`,
    url
  )
}

export const deleteShortenedURL = (urlID) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/urlshortener/${brandId}/urls/${urlID}`
  )
}
