import {
  GET_BRANCH_DASHBOARD,
  FETCH_BRANCH_PERFORMANCE_TREND,
  FETCH_BRANCH_RESPONSE_TREND,
  FETCH_BRANCH_NPS_TREND,
  FETCH_BRANCH_STAFF_PERFORMANCE,
  FETCH_BRANCHES,
  FETCH_BRANCH,
  DELETE_BRANCH,
  SELECT_BRANCH,
  UPDATE_BRANCH,
  ADD_BRANCH,
} from 'src/store/actions/types'

const INITIAL_STATE = {
  categories: [],
  comments: [],
  complaints: [],
  performance_line_chart: [],
  most_active_staff: '',
  neg_bar: [],
  pos_bar: [],
  review_count: '',
  staff_leaderboard: [],
  today_score: '',
  branches: [],
  defaultBranch: {},
  selectedBranch: { name: '', address: '', survey: '', success_factors: [] },
  branchLocation: {},
  dashboard: {
    score: -1,
    customer_satisfaction: null,
    nps_score: null,
    branch_performance: [],
    staff_performance: [],
    multi_choice: { results: [] },
  },
  performanceTrend: [],
  responseTrend: [],
  npsTrend: [],
  staffPerformance: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_BRANCH_DASHBOARD:
      return { ...state, dashboard: payload }
    case FETCH_BRANCHES:
      return {
        ...state,
        branches: Array.isArray(payload) ? payload : [payload],
        defaultBranch: payload?.[0] || [payload][0] || {},
      }
    case FETCH_BRANCH:
      return { ...state, branchLocation: payload }
    case FETCH_BRANCH_PERFORMANCE_TREND:
      return { ...state, performanceTrend: payload }
    case FETCH_BRANCH_RESPONSE_TREND:
      return { ...state, responseTrend: payload }
    case FETCH_BRANCH_NPS_TREND:
      return { ...state, npsTrend: payload }
    case SELECT_BRANCH:
      return { ...state, selectedBranch: payload }
    case DELETE_BRANCH:
      return { ...state, branches: payload }
    case UPDATE_BRANCH:
      return { ...state, branches: payload }
    case ADD_BRANCH:
      return { ...state, branches: payload }
    case FETCH_BRANCH_STAFF_PERFORMANCE:
      return { ...state, staffPerformance: payload }
    default:
      return state
  }
}

// const INITIAL_STATE = {
// dashboard: {
//   numberOfDailyReviews: 0,
//   numberOfWeeklyReviews: 0,
//   staffDailyScore: 0,
//   staffWeeklyScore: 0,
//   branchDailyScore: 0,
//   branchWeeklyScore: 0,
//   branchRatings: { labels: [], data: [] },
//   staffRatings: { labels: [], data: [] },
//   staffPositive: { labels: [], data: [] },
//   staffNegative: { labels: [], data: [] },
//   foodPositive: { labels: [], data: [] },
//   foodNegative: { labels: [], data: [] },
//   comments: [],
//   staff: []
// },
// branches: []
// }

// export default (state = initialState, action) => {
//   const newState = { ...state }
//   switch (action.type) {
//     case actionTypes.GET_BRANCH_DASHBOARD:
//       newState.dashboard = action.payload
//       newState.isLoaded = true
//       break
//     case actionTypes.RELOAD_BRANCH_DASHBOARD:
//       newState.isLoaded = false
//       break
//     default:
//       break
//   }
//   return newState
// }
