import React from 'react'

export const TrashIcon = ({ color = '#607D8B', width = '14', height = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1944 2.77778H10.4167V2.08333C10.4167 0.902778 9.51389 0 8.33333 0H5.55555C4.375 0 3.47222 0.902778 3.47222 2.08333V2.77778H0.694444C0.277778 2.77778 0 3.05556 0 3.47222C0 3.88889 0.277778 4.16667 0.694444 4.16667H1.38889V13.1944C1.38889 14.375 2.29167 15.2778 3.47222 15.2778H10.4167C11.5972 15.2778 12.5 14.375 12.5 13.1944V4.16667H13.1944C13.6111 4.16667 13.8889 3.88889 13.8889 3.47222C13.8889 3.05556 13.6111 2.77778 13.1944 2.77778ZM4.86111 2.08334C4.86111 1.66667 5.13889 1.3889 5.55555 1.3889H8.33333C8.75 1.3889 9.02778 1.66667 9.02778 2.08334V2.77778H4.86111V2.08334ZM10.4167 13.8889C10.8333 13.8889 11.1111 13.6111 11.1111 13.1944V4.16667H2.77778V13.1944C2.77778 13.6111 3.05555 13.8889 3.47222 13.8889H10.4167Z"
        fill={color}
      />
    </svg>
  )
}
