/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Drawer,
  List,
  ListSubheader,
  Hidden,
  colors,
  Grid,
  Box,
  Button,
} from '@material-ui/core'
import { MEDIA_URL } from 'src/Config'
import { getDashboardBrandDetailsRoute } from 'src/constants'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import NavItem from 'src/components/Singles/NavItem'
import Gate from 'src/components/Singles/Gate'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1,
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
  hr: {
    width: '224px',
    height: '1px',
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    marginBottom: '16px',
  },
  copyright: {
    marginLeft: '16px',
  },
  line1: {
    width: '190px',
    height: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.3px',
    color: '#546E7A',
    marginBottom: '6px',
  },
  line2: {
    width: '224px',
    height: '28px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.3px',
    color: '#546E7A',
    marginBottom: '16px',
  },
  header: {
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '20px',
  },
  brandContainer: {
    width: '80%',
    backgroundColor: 'rgba(188, 195, 207, 0.22)',
    height: '80%',
    borderRadius: '13px',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  brandLogo: {
    width: '50%',
    backgroundColor: '#C2C6D2',
    height: 'auto',
    minHeight: '100%',
    borderRadius: '14px',
  },
}))

function renderNavItems({
  items,
  subheader,
  // key,
  ...rest
}) {
  return (
    <List key={uuid()}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, ...rest }), [])}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })
    acc.push(
      <Gate gate={item.gate} key={uuid()}>
        <NavItem
          depth={depth}
          icon={item.icon}
          key={item.href}
          label={item.label}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 2,
            pathname,
            items: item.items,
          })}
        </NavItem>
      </Gate>
    )
  } else {
    acc.push(
      <Gate gate={item.gate} key={uuid()}>
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={item.href}
          label={item.label}
          title={item.title}
        />
      </Gate>
    )
  }
  return acc
}

function NavBar({
  openMobile,
  onMobileClose,
  className,
  navConfig,
  brand,
  role,
  ...rest
}) {
  const classes = useStyles()
  const location = useLocation()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [])

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.header}>
        {brand.id && (
          <Box
            className={classes.brandContainer}
            component={Link}
            to={
              role === 'owner' || role === 'surv'
                ? getDashboardBrandDetailsRoute()
                : ''
            }
          >
            <img
              className={classes.brandLogo}
              src={
                brand.logo.startsWith('https')
                  ? brand?.logo
                  : `${MEDIA_URL}${brand?.logo}`
              }
              alt="logo"
            />
            <Box p={1}>
              <Button
                component={Link}
                to={
                  role === 'owner' || role === 'surv'
                    ? getDashboardBrandDetailsRoute()
                    : ''
                }
                endIcon={<ArrowForwardIosIcon />}
              >
                {brand?.name}
              </Button>
            </Box>
          </Box>
        )}
        {!brand.id && <Skeleton variant="rect" className={classes.brandContainer} />}
      </Box>

      <nav className={classes.navigation}>
        {navConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
          })
        )}
      </nav>
      <Grid className={classes.copyright}>
        <Grid item className={classes.hr} />
        <Grid item className={classes.line1}>
          © Copyright to Company Surv
        </Grid>
        <Grid item className={classes.line2}>
          Feel free to contact us in case of any confusion
        </Grid>
      </Grid>
    </div>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
