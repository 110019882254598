import React from 'react'

export const WelcomeLogoIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.70813 34.3442L12.499 40.4971L20.2898 29.404L28.3889 41L36.3759 34.847L28.3889 23.3694L41 19.1392L38.1134 9.55483L25.5024 13.9033V0H15.6938V13.9033L3.0827 9.17027L0 18.7251L12.7792 23.3694L4.70813 34.3442Z"
        fill="#0052D4"
      />
    </svg>
  )
}

export default WelcomeLogoIcon
