import {
  GET_MEMBER_DASHBOARD,
  FETCH_STAFF_RESPONSE_TREND,
  FETCH_STAFF_PERFORMANCE_TREND,
  FETCH_STAFF_NPS_TREND,
} from './types'

export const fetchMemberStatsAction = (data) => (dispatch) => {
  dispatch({
    type: GET_MEMBER_DASHBOARD,
    payload: data,
  })
}
export const fetchStaffPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_STAFF_PERFORMANCE_TREND, payload: data })
}
export const fetchStaffResponseTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_STAFF_RESPONSE_TREND, payload: data })
}
export const fetchStaffNPSTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_STAFF_NPS_TREND, payload: data })
}
