import {
  FETCH_REGIONS,
  DELETE_REGION,
  FETCH_REGION,
  UPDATE_REGION,
  ADD_REGION,
  SELECT_REGION,
  VIEW_REGION_AREAS,
  VIEW_REGION_BRANCHES,
  GET_REGION_DASHBOARD,
  FETCH_REGION_PERFORMANCE_TREND,
  FETCH_REGION_RESPONSE_TREND,
  FETCH_REGION_NPS_TREND,
  FETCH_REGION_AREAS_PERFORMANCE,
  FETCH_REGION_BRANCHES_PERFORMANCE,
  FETCH_REGION_STAFF_PERFORMANCE,
} from '../actions/types'

const INITIAL_STATE = {
  regions: [],
  region: null,
  regionAreas: '',
  regionBranches: '',
  selectedRegion: { name: '' },
  dashboard: {
    score: -1,
    customer_satisfaction: null,
    nps_score: null,
    branch_performance: [],
    staff_performance: [],
  },
  performanceTrend: [],
  responseTrend: [],
  npsTrend: [],
  areasPerformance: [],
  branchesPerformance: [],
  staffPerformance: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_REGION_DASHBOARD:
      return { ...state, dashboard: { ...payload } }
    case FETCH_REGIONS:
      return { ...state, regions: payload, selectedRegion: payload[0] || {} }
    case FETCH_REGION:
      return { ...state, region: payload }
    case FETCH_REGION_PERFORMANCE_TREND:
      return { ...state, performanceTrend: payload }
    case FETCH_REGION_RESPONSE_TREND:
      return { ...state, responseTrend: payload }
    case FETCH_REGION_NPS_TREND:
      return { ...state, npsTrend: payload }
    case DELETE_REGION:
      return { ...state, regions: payload }
    case UPDATE_REGION:
      return { ...state, regions: payload }
    case ADD_REGION:
      return { ...state, regions: payload }
    case SELECT_REGION:
      return { ...state, selectedRegion: payload }
    case VIEW_REGION_AREAS:
      return { ...state, regionAreas: payload }
    case VIEW_REGION_BRANCHES:
      return { ...state, regionBranches: payload }
    case FETCH_REGION_AREAS_PERFORMANCE:
      return { ...state, areasPerformance: payload }
    case FETCH_REGION_BRANCHES_PERFORMANCE:
      return { ...state, branchesPerformance: payload }
    case FETCH_REGION_STAFF_PERFORMANCE:
      return { ...state, staffPerformance: payload }

    default:
      return state
  }
}
