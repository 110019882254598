import React from 'react'

export const CalendarIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.52268 9.7845C5.02881 9.7845 4.62473 9.35706 4.62473 8.83463C4.62473 8.31221 5.02881 7.88477 5.52268 7.88477C6.01656 7.88477 6.42064 8.31221 6.42064 8.83463C6.42064 9.35706 6.01656 9.7845 5.52268 9.7845Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M5.52268 13.4876C5.02881 13.4876 4.62473 13.0602 4.62473 12.5378C4.62473 12.0153 5.02881 11.5879 5.52268 11.5879C6.01656 11.5879 6.42064 12.0153 6.42064 12.5378C6.42064 13.0602 6.01656 13.4876 5.52268 13.4876Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M8.97972 9.7845C8.75523 9.7845 8.53074 9.68951 8.35114 9.49954C7.99196 9.11959 7.99196 8.54967 8.35114 8.16973C8.71033 7.78978 9.2491 7.78978 9.60829 8.16973C9.96747 8.54967 9.96747 9.16709 9.60829 9.54703C9.4287 9.68951 9.20421 9.7845 8.97972 9.7845Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M8.97972 13.4876C8.75523 13.4876 8.53074 13.3926 8.35114 13.2027C7.99196 12.8227 7.99196 12.2528 8.35114 11.8729C8.71033 11.4929 9.2491 11.4929 9.60829 11.8729C9.96747 12.2528 9.96747 12.8702 9.60829 13.2502C9.4287 13.3926 9.20421 13.4876 8.97972 13.4876Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M12.4367 9.7845C11.9429 9.7845 11.5388 9.35706 11.5388 8.83463C11.5388 8.31221 11.9429 7.88477 12.4367 7.88477C12.9306 7.88477 13.3347 8.31221 13.3347 8.83463C13.3347 9.35706 12.9306 9.7845 12.4367 9.7845Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M12.4367 13.4876C11.9429 13.4876 11.5388 13.0602 11.5388 12.5378C11.5388 12.0153 11.9429 11.5879 12.4367 11.5879C12.9306 11.5879 13.3347 12.0153 13.3347 12.5378C13.3347 13.0602 12.9306 13.4876 12.4367 13.4876Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M17.9592 4.13193V15.6253C17.9592 16.9551 16.9714 18 15.7143 18H2.2449C0.987755 18 0 16.9551 0 15.6253V4.13193C0 2.80211 0.987755 1.75726 2.2449 1.75726H4.62449V0.949868C4.62449 0.427441 5.02857 0 5.52245 0C6.01633 0 6.42041 0.427441 6.42041 0.949868V1.75726H11.5388V0.949868C11.5388 0.427441 11.9429 0 12.4367 0C12.7061 0 12.9306 0.0949868 13.0653 0.28496C13.2449 0.474934 13.3347 0.712401 13.3347 0.949868V4.51187C13.3347 5.0343 12.9306 5.46174 12.4367 5.46174C11.9429 5.46174 11.5388 5.0343 11.5388 4.51187V3.65699H6.42041V4.55937C6.42041 5.08179 6.01633 5.50923 5.52245 5.50923C5.02857 5.50923 4.62449 5.08179 4.62449 4.55937V3.65699H2.2449C1.97551 3.65699 1.79592 3.84697 1.79592 4.13193V15.6253C1.79592 15.9103 1.97551 16.1003 2.2449 16.1003H15.7143C15.9837 16.1003 16.1633 15.9103 16.1633 15.6253V4.13193C16.1633 3.84697 15.9837 3.65699 15.7143 3.65699H14.951C14.4571 3.65699 14.0531 3.22955 14.0531 2.70712C14.0531 2.1847 14.4571 1.75726 14.951 1.75726H15.7143C16.9714 1.75726 17.9592 2.80211 17.9592 4.13193Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  )
}
