import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import {
  deleteApprovedStaff,
  deletePendingStaff,
  deleteBranch,
  deleteQRCard,
  deleteMenu,
  deleteMenuGroup,
  deleteDynamicMenu,
  deleteMenuItem,
  retrieveDynamicMenu,
  deleteGroupItem,
  deleteStep,
  deleteOrderItem,
  deleteSurveyAPI,
  deleteRegion,
  deleteArea,
  deleteQrTemplate,
  deleteShortenedURL,
  deleteSurveysFolder,
  removeSurveysFromFolder,
} from 'src/API'

import {
  closeModal,
  deleteMenuAction,
  deleteQRCardAction,
  deleteApprovedStaffAction,
  deletePendingStaffAction,
  deleteBranchAction,
  selectDynamicMenuAction,
  updateDynamicMenuAction,
  deleteDynamicMenuAction,
  deleteMenuItemAction,
  deleteRegionAction,
  deleteAreaAction,
  deleteSurveyAction,
  deleteTemplateAction,
  deleteShortenedURLAction,
  updateOpenedFolderAction,
} from 'src/store/actions'
import { useHistory } from 'react-router'
import { getQrCardsRoute } from 'src/constants'
import ButtonWithLoader from './ButtonWithLoader'

const Modal = ({
  modal,
  isDeleting,
  closeModal,
  selectedMenu,
  deleteMenuAction,
  deleteQRCardAction,
  deletePendingStaffAction,
  deleteApprovedStaffAction,
  selectDynamicMenuAction,
  deleteBranchAction,
  deleteDynamicMenuAction,
  deleteMenuItemAction,
  deleteSurveyAction,
  deleteRegionAction,
  deleteAreaAction,
  deleteTemplateAction,
  deleteShortenedURLAction,
  updateOpenedFolderAction,
}) => {
  const handleClose = useCallback(() => {
    if (!isDeleting) closeModal()
  }, [closeModal, isDeleting])

  const history = useHistory()

  useEffect(() => {
    if (!isDeleting) handleClose()
  }, [handleClose, isDeleting])

  const handleAccept = async () => {
    const {
      id,
      index,
      brandId,
      uuid,
      payload,
      brand,
      location,
      filteredCards,
      filteredSelectCards,
      staffId,
      data,
      groupId,
      filteredGroups,
      removeGroupField,
      itemId,
      menuId,
      stepId,
      stepIndex,
      arrayHelpers,
      regionId,
      areaId,
      templateId,
      urlID,
      filterFolders,
      folderId,
    } = modal.data
    switch (modal.item) {
      case 'menu':
        await deleteMenu(brandId, uuid)
        deleteMenuAction(payload)
        break
      case 'dynamicMenu':
        await deleteDynamicMenu(uuid)
        deleteDynamicMenuAction(payload)
        break
      case 'menuItem':
        await deleteMenuItem(itemId)
        deleteMenuItemAction(payload)
        break
      case 'groupItem':
        await deleteGroupItem(groupId, itemId)
        if (menuId) {
          const { data } = await retrieveDynamicMenu(menuId)
          selectDynamicMenuAction(data)
        }
        break
      case 'menuCategory':
        if (groupId) {
          await deleteMenuGroup({ uuid, id: groupId })
        }
        removeGroupField()
        selectDynamicMenuAction({ ...selectedMenu, menu_groups: filteredGroups })
        break
      case 'card':
        await deleteQRCard({ brand, uuid })
        if (filteredCards && filteredSelectCards)
          deleteQRCardAction({ filteredCards, filteredSelectCards })
        history.replace(getQrCardsRoute({ branchId: location }))
        break
      case 'step':
        await deleteStep(stepId)
        arrayHelpers.remove(stepIndex)
        break
      case 'approvedStaff':
        await deleteApprovedStaff(brandId, location, staffId)
        deleteApprovedStaffAction(data)
        break
      case 'pendingStaff':
        await deletePendingStaff(brandId, location, staffId)
        deletePendingStaffAction(data)
        break
      case 'branch':
        await deleteBranch(location)
        deleteBranchAction(data)
        break
      case 'orderItem':
        await deleteOrderItem(id)
        arrayHelpers.remove(index)
        break
      case 'folder':
        await deleteSurveysFolder(id)
        filterFolders()
        break
      case 'removeSurveyFromFolder':
        await removeSurveysFromFolder(folderId, [id])
        updateOpenedFolderAction(data)
        handleClose()
        break
      case 'survey':
        await deleteSurveyAPI(id)
        deleteSurveyAction(data)
        break
      case 'region':
        await deleteRegion(regionId)
        deleteRegionAction(data)
        break
      case 'area':
        await deleteArea(areaId)
        deleteAreaAction(data)
        break
      case 'qrTemplate':
        await deleteQrTemplate(templateId)
        deleteTemplateAction(data)
        break
      case 'shortenedURL':
        await deleteShortenedURL(urlID)
        deleteShortenedURLAction(data)
        break
      default:
        break
    }
  }

  return (
    <Dialog onClose={handleClose} open={modal.open} fullWidth>
      <DialogTitle>
        <Typography variant="h4">{modal.title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: '30px' }}>
        <Typography variant="body1">{modal.message}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ButtonWithLoader
          variant="contained"
          title="Yes"
          color="secondary"
          style={{
            backgroundColor: colors.red[600],
            color: 'white',
          }}
          onClick={handleAccept}
          Delete
        />
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = ({ modal, pending, menus }) => ({
  modal,
  isDeleting: pending.delete,
  selectedMenu: menus.selectedDynamicMenu,
  dynamicMenus: menus.dynamicMenus,
})

export default connect(mapStateToProps, {
  closeModal,
  deleteMenuAction,
  deleteQRCardAction,
  deletePendingStaffAction,
  deleteApprovedStaffAction,
  deleteBranchAction,
  selectDynamicMenuAction,
  updateDynamicMenuAction,
  deleteDynamicMenuAction,
  deleteMenuItemAction,
  deleteSurveyAction,
  deleteRegionAction,
  deleteAreaAction,
  deleteTemplateAction,
  deleteShortenedURLAction,
  updateOpenedFolderAction,
})(Modal)
