import React from 'react'

export const ResizeIcon = ({ color = 'white' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99501 11.4838L2.8404 15.6384H5.76559C6.23192 15.6384 6.61347 16.02 6.61347 16.4863C6.61347 16.9526 6.23192 17.3342 5.76559 17.3342H0.805486C0.763092 17.3342 0.678304 17.3342 0.63591 17.3342C0.593516 17.3342 0.551122 17.2918 0.466334 17.2918C0.42394 17.2918 0.381547 17.2494 0.339153 17.2494C0.339153 17.2494 0.339152 17.2494 0.296758 17.2494C0.254364 17.207 0.21197 17.207 0.169576 17.1646C0.127182 17.1222 0.127182 17.0798 0.0847884 17.0374L0.0423942 16.995C0.0423942 16.8678 0.0423942 16.8254 0.0423942 16.8254V16.783C0.0423942 16.7406 0 16.6983 0 16.6559C0 16.6135 0 16.5287 0 16.4863V11.4838C0 11.0175 0.381546 10.6359 0.84788 10.6359C1.31421 10.6359 1.69576 11.0175 1.69576 11.4838V14.409L5.85037 10.2544C6.18953 9.91521 6.69825 9.91521 7.03741 10.2544C7.33416 10.6359 7.33417 11.1446 6.99501 11.4838Z"
        fill={color}
      />
      <path
        d="M17.5225 0.84788V5.80798C17.5225 6.27431 17.1409 6.65586 16.6746 6.65586C16.2083 6.65586 15.8267 6.27431 15.8267 5.80798V2.88279L11.6721 7.03741C11.5025 7.20698 11.2906 7.29177 11.0786 7.29177C10.8666 7.29177 10.6547 7.20698 10.4851 7.03741C10.1459 6.69826 10.1459 6.18953 10.4851 5.85037L14.6397 1.69576H11.6721C11.2058 1.69576 10.8242 1.31421 10.8242 0.84788C10.8242 0.381546 11.2058 0 11.6721 0H16.6322H16.6746H16.717C16.8018 0 16.8866 0.0423942 16.929 0.0423942C16.9714 0.0423942 17.0138 0.0847884 17.0561 0.0847884C17.0985 0.0847884 17.1409 0.127183 17.1409 0.127183C17.1833 0.169577 17.2257 0.169576 17.2257 0.21197C17.2681 0.254364 17.2681 0.296759 17.3105 0.339153L17.3529 0.381547C17.4377 0.466335 17.4377 0.508729 17.4377 0.508729V0.551123C17.4377 0.593517 17.4801 0.63591 17.4801 0.678304C17.5225 0.763092 17.5225 0.805486 17.5225 0.84788Z"
        fill={color}
      />
    </svg>
  )
}
