import { BASE_URL } from 'src/Config'
import axiosInstance from './axios'

export const updateUser = (data) => {
  return axiosInstance.patch(`${BASE_URL}/api/auth/user/`, data)
}

export const sendResetPasswordEmail = (data) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/password/reset/`, data)
}

export const resetPassword = (data) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/password/reset/confirm/`, data)
}

export const confirmEmail = (data) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/registration/verify-email/`, data)
}

export const changePassword = (data) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/password/change/`, data)
}
