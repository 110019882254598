import React from 'react'

export const QrTabIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9279 0H10.178C9.58742 0 9.10742 0.479966 9.10742 1.07206V4.82202C9.10742 5.41257 9.58739 5.89257 10.178 5.89257H13.9279C14.52 5.89257 15 5.4126 15 4.82202V1.07206C15 0.479966 14.52 0 13.9279 0Z"
        fill="#C2C6D2"
      />
      <path
        d="M4.82202 9.10742H1.07206C0.479966 9.10742 0 9.58739 0 10.178V13.9279C0 14.52 0.479966 15 1.07206 15H4.82202C5.41257 15 5.89257 14.52 5.89257 13.9279V10.178C5.89257 9.58742 5.4126 9.10742 4.82202 9.10742Z"
        fill="#C2C6D2"
      />
      <path
        d="M0.53521 8.03529H7.49998C7.79527 8.03529 8.03524 7.7953 8.03524 7.50003V0.535257C8.03524 0.239969 7.79525 0 7.49998 0C7.20471 0 6.96472 0.239989 6.96472 0.535257V6.9646H0.535379C0.240091 6.9646 0.00012207 7.20459 0.00012207 7.49986C0.00012207 7.79513 0.240111 8.03512 0.535379 8.03512L0.53521 8.03529Z"
        fill="#C2C6D2"
      />
      <path
        d="M1.07206 5.89257H4.82202C5.41257 5.89257 5.89257 5.4126 5.89257 4.82202V1.07206C5.89257 0.479966 5.4126 0 4.82202 0H1.07206C0.479966 0 0 0.479966 0 1.07206V4.82202C0 5.41257 0.479966 5.89257 1.07206 5.89257Z"
        fill="#C2C6D2"
      />
      <path
        d="M10.1785 10.1786V7.50006C10.1785 7.20424 9.93866 6.96436 9.64284 6.96436C9.34701 6.96436 9.10713 7.20423 9.10713 7.50006V9.6429H7.5C7.20418 9.6429 6.96429 9.88278 6.96429 10.1786V13.9286C6.96429 14.2244 7.20417 14.4643 7.5 14.4643C7.79583 14.4643 8.03571 14.2244 8.03571 13.9286V10.7143H9.64284C9.93866 10.7143 10.1785 10.4744 10.1785 10.1786Z"
        fill="#C2C6D2"
      />
      <path
        d="M13.9285 11.7856H9.64283C9.347 11.7856 9.10712 12.0255 9.10712 12.3214C9.10712 12.6172 9.347 12.8571 9.64283 12.8571H10.7142V13.9285C10.7142 14.2243 10.9541 14.4642 11.2499 14.4642C11.5458 14.4642 11.7857 14.2243 11.7857 13.9285V12.8571H13.3928V13.9285C13.3928 14.2243 13.6327 14.4642 13.9285 14.4642C14.2243 14.4642 14.4642 14.2243 14.4642 13.9285V12.3214C14.4642 12.0255 14.2243 11.7856 13.9285 11.7856Z"
        fill="#C2C6D2"
      />
      <path
        d="M14.4642 8.03577C14.76 8.03577 14.9999 7.79589 14.9999 7.50006C14.9999 7.20423 14.76 6.96436 14.4642 6.96436H11.7856C11.4898 6.96436 11.2499 7.20423 11.2499 7.50006V10.1786C11.2499 10.4744 11.4898 10.7143 11.7856 10.7143H14.4642C14.76 10.7143 14.9999 10.4744 14.9999 10.1786C14.9999 9.88267 14.76 9.6429 14.4642 9.6429H12.3214V8.03577H14.4642Z"
        fill="#C2C6D2"
      />
    </svg>
  )
}
