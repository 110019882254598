import { BASE_URL } from 'src/Config'
import axiosInstance from './axios'

export const addBranch = (brandId, branch) => {
  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/${brandId}/location/create`,
    branch
  )
}

export const updateBranch = (branch) => {
  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/${branch.brand}/location/${branch.id}`,
    branch
  )
}

export const deleteBranch = (branch) => {
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${branch.brand}/location/${branch.id}`
  )
}
