import React from 'react'
import { Box, Checkbox, Button, MenuItem, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Modal } from 'src/components/Singles/Modal'
import { Field } from 'src/components/Field'
import ButtonWithLoader from 'src/components/Singles/ButtonWithLoader'
import validationSchema from './validationSchema'

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #C2C6D2',
  },
  dialogTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '120%',
    letterSpacing: '0.005em',
    color: '#11142D',
  },
  ctaButton: {
    color: '#0052D4',
    cursor: 'pointer',
    padding: '0px',
    '&:last-child': {
      justifyContent: 'flex-end',
    },
    '&:hover': {
      color: '#0868ff',
      background: '#FFF',
    },
  },
  fieldTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '120%',
    fontWeight: 700,
    marginBottom: '10px',
    color: '#11142D',
    letterSpacing: '0.005em',
  },
}))

const BranchModal = ({
  open,
  surveys,
  areas,
  handleCloseModal,
  handleSubmit,
  successFactors,
  selectedBranch,
}) => {
  const classes = useStyles()

  return (
    <Modal open={open} handleClose={handleCloseModal} minWidth="600px">
      <Formik
        initialValues={selectedBranch}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Box className={classes.header}>
              <Typography className={classes.dialogTitle}>
                {selectedBranch?.id ? 'Edit' : 'Add'} Branch
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleCloseModal}
                  className={`${classes.dialogTitle} ${classes.ctaButton}`}
                >
                  Cancel
                </Button>
                <ButtonWithLoader
                  type="submit"
                  color="secondary"
                  title="Save"
                  className={`${classes.dialogTitle} ${classes.ctaButton}`}
                />
              </Box>
            </Box>
            <Box mt={4}>
              <Typography className={classes.fieldTitle}>Branch name</Typography>
              <Field
                name="name"
                type="text"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
              />
            </Box>
            <Box mt={4}>
              <Typography className={classes.fieldTitle}>Branch address</Typography>
              <Field
                name="address"
                type="text"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
              />
            </Box>
            <Box mt={4}>
              <Typography className={classes.fieldTitle}>Branch area</Typography>
              <Field
                select
                name="area"
                type="text"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
              >
                {areas?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Box mt={4}>
              <Typography className={classes.fieldTitle}>Survey</Typography>
              <Field
                select
                name="survey"
                type="text"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
              >
                {surveys?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
            </Box>
            <Box mt={4}>
              <Typography className={classes.fieldTitle}>Success Factors</Typography>
              <Autocomplete
                multiple
                autoComplete
                onChange={(e, value) =>
                  setFieldValue(
                    'success_factors',
                    value.map((v) => v.id)
                  )
                }
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </>
                )}
                defaultValue={selectedBranch.success_factors}
                getOptionLabel={(option) => option.name}
                options={successFactors}
                renderInput={(params) => (
                  <Field {...params} variant="outlined" name="success_factors" />
                )}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default BranchModal
