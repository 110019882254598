import { SET_CURRENT_USER } from 'src/store/actions/types'
import axiosInstance from 'src/API/axios'

export const setAuthToken = (token) => {
  if (token) axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  else delete axiosInstance.defaults.headers.common.Authorization
}

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  }
}
