import { BASE_URL } from 'src/Config'
import { getCurrentBrandId } from 'src/utils/auth'
import axiosInstance from './axios'

// coupons
export const fetchCoupons = (page = 0, search) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/coupons/?limit=10&page=${
      search ? 1 : page
    }&search=${search}`
  )
}

export const createCoupon = (coupon) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/coupons/`, coupon)
}

// followers

export const fetchFollowers = (searchQuery = '') => {
  return axiosInstance.get(
    `${BASE_URL}/api/followers/users/?limit=20&search=${searchQuery}`
  )
}

// rewards
export const createReward = (reward) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/${brand}/sprints/rewards/`,
    reward
  )
}

export const createMultipleRewards = (rewards) => {
  const brand = getCurrentBrandId()
  return axiosInstance.all(
    rewards.map((reward) =>
      axiosInstance.post(
        `${BASE_URL}/api/dashboards/${brand}/sprints/rewards/`,
        reward
      )
    )
  )
}

export const fetchRewards = (page = 0, searchQuery) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/sprints/rewards/?limit=10&search=${searchQuery}&page=${
      searchQuery ? 1 : page
    }`
  )
}

// fetch sprints

export const fetchSprints = (type, search, page) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/sprints/${type}/?limit=10&page=${
      search ? 1 : page
    }&search=${search}`
  )
}

// create sprints

export const createSprint = (sprint, type) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/${brand}/sprints/${type}/`,
    sprint
  )
}

export const updateSprint = ({ sprint, type, id }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/${brand}/sprints/${type}/${id}/`,
    sprint
  )
}

// retrieve sprints

export const retrieveSprint = (id, type) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/sprints/${type}/${id}`
  )
}

// delete step

export const deleteStep = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${brand}/sprints/sprint-steps/${id}`
  )
}
