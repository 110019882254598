import React from 'react'

export const DashboardIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21008 0.5H5.12008C5.81495 0.5 6.48135 0.776035 6.9727 1.26738C7.46404 1.75873 7.74008 2.42513 7.74008 3.12V5C7.74008 5.34406 7.67231 5.68476 7.54064 6.00263C7.40898 6.3205 7.21599 6.60933 6.9727 6.85262C6.72941 7.09591 6.44058 7.2889 6.12271 7.42056C5.80484 7.55223 5.46414 7.62 5.12008 7.62H3.21008C2.51429 7.62001 1.84685 7.34429 1.35391 6.85323C0.86098 6.36217 0.582724 5.69579 0.580078 5V3.13C0.580078 2.43248 0.857167 1.76353 1.35039 1.27031C1.84361 0.777088 2.51256 0.5 3.21008 0.5Z"
        fill="#BCC3CF"
      />
      <path
        d="M3.21008 10.33H5.12008C5.46923 10.3286 5.81514 10.397 6.13748 10.5312C6.45982 10.6654 6.75209 10.8627 6.9971 11.1114C7.24212 11.3601 7.43495 11.6554 7.56426 11.9797C7.69357 12.304 7.75674 12.6509 7.75008 13V14.91C7.73957 15.6006 7.45786 16.2593 6.96577 16.7439C6.47368 17.2285 5.81072 17.5001 5.12008 17.5H3.21008C2.51256 17.5 1.84361 17.2229 1.35039 16.7297C0.857167 16.2365 0.580078 15.5675 0.580078 14.87V13C0.580078 12.3051 0.856113 11.6387 1.34746 11.1474C1.8388 10.656 2.50521 10.38 3.20008 10.38L3.21008 10.33Z"
        fill="#BCC3CF"
      />
      <path
        d="M12.88 0.5H14.79C15.4875 0.5 16.1565 0.777088 16.6497 1.27031C17.1429 1.76353 17.42 2.43248 17.42 3.13V5C17.42 5.69487 17.144 6.36127 16.6526 6.85262C16.1613 7.34397 15.4949 7.62 14.8 7.62H12.88C12.1842 7.62001 11.5168 7.34429 11.0238 6.85323C10.5309 6.36217 10.2526 5.69579 10.25 5V3.13C10.25 2.43248 10.5271 1.76353 11.0203 1.27031C11.5135 0.777088 12.1825 0.5 12.88 0.5Z"
        fill="#BCC3CF"
      />
      <path
        d="M12.88 10.33H14.79C15.1388 10.33 15.4841 10.3993 15.8058 10.534C16.1275 10.6686 16.4192 10.8659 16.664 11.1144C16.9087 11.3629 17.1016 11.6575 17.2314 11.9813C17.3612 12.305 17.4253 12.6513 17.42 13V14.91C17.42 15.6075 17.1429 16.2765 16.6497 16.7697C16.1565 17.2629 15.4875 17.54 14.79 17.54H12.88C12.1859 17.5374 11.5211 17.2605 11.0303 16.7697C10.5395 16.2789 10.2626 15.6141 10.26 14.92V13C10.2534 12.6518 10.3162 12.3057 10.4449 11.982C10.5736 11.6583 10.7655 11.3636 11.0095 11.115C11.2534 10.8664 11.5445 10.6689 11.8657 10.5341C12.1869 10.3993 12.5317 10.3299 12.88 10.33Z"
        fill="#BCC3CF"
      />
    </svg>
  )
}
