import store from 'src/store'
// store.getState().branch

export const loginRoute = '/auth/login'
export const registrationRoute = '/auth/register'
export const confirmEmailRoute = '/auth/registration/account-confirm-email/:key'
export const resetPasswordRoute = '/auth/password/reset'
export const confirmResetPasswordRoute = '/auth/password/reset/confirm/:uid/:token'
export const emailSentRoute = '/auth/password/reset/email-sent/:email'

export const welcomeRoute = '/welcome'
export const dashboardWelcomeRoute = '/dashboard/welcome'

export const brandRoute = '/venue/brand'
export const brandDetailsRoute = '/venue/brand/'
export const dashboardBrandDetailsRoute = '/dashboard/venue/brand/'

export const dashboardProfileRoute = '/dashboard/profile'
export const profileRoute = '/welcome/profile'

export const customerRoute = '/dashboard/brand/customer'

export const dashboardRoute = '/dashboard'
export const branchDashboardRoute = '/branches/dashboard/:id'
export const dashboardTabs = {
  brand: 'brand',
  district: 'district',
  area: 'area',
  branch: 'branch',
  staff: 'staff',
  folders: 'folders',
}

export const getDashboardRoute = ({ tab = dashboardTabs.brand }, branchId) => {
  let url = `/dashboard/?tab=${tab}`
  if (tab === dashboardTabs.district)
    url += `&regionId=${store.getState()?.region?.selectedRegion?.id}`
  else if (tab === dashboardTabs.area)
    url += `&areaId=${store.getState()?.area?.selectedArea?.id}`
  else if (tab === dashboardTabs.branch) {
    const branch = branchId || store.getState()?.branch?.defaultBranch?.id
    url += `&branchId=${branch}`
  } else if (tab === dashboardTabs.staff) {
    url += `&branchId=${store.getState()?.branch?.defaultBranch?.id}`
  }

  return url
}

export const messageRoute = '/dashboard/message'
export const messageDetailsRoute = '/dashboard/message/:page/:id'

export const dashboardSubscribeRoute = '/dashboard/subscribe'
export const subscribeRoute = '/welcome/subscribe'
export const joinQRRoute = '/dashboard/join-qr'

export const surveyRoute = '/dashboard/survey'
export const surveysFolderRoute = '/dashboard/survey/folders/:folderId'

export const surveyTabs = {
  surveys: 'surveys',
  folders: 'folders',
}

export const getSurveyTab = ({ tab }) => `/dashboard/survey/?tab=${tab}`

export const getFolderSurveys = (folderId) => `/dashboard/survey/folders/${folderId}`

export const surveyDetailsRoute = '/dashboard/survey/:id'
export const surveyAnalyticsRoute = '/dashboard/survey/:id/analytics'

export const branchRoute = '/dashboard/branches'
export const branchDetailsRoute = '/branches/:id'

export const menuRoute = '/dashboard/menu/:menuId?'
export const menuTabs = {
  menus: 'menus',
  items: 'items',
  menuDetails: 'menu-details',
}
export const getMenuRoute = ({ menuId, tab }) =>
  `/dashboard/menu/${menuId || ''}?tab=${tab}`

export const managementRoute = '/dashboard/management/'
export const managementTabs = {
  brand: 'brand',
  region: 'region',
  area: 'area',
  branch: 'branch',
}
export const getManagementRoute = ({ tab }) => `/dashboard/management/?tab=${tab}`

export const qrCardsRoute = '/dashboard/qr-cards/'

export const qrCardsTabs = {
  qrCards: 'qrCards',
  templates: 'templates',
}
export const getQrCardsRoute = ({ tab = qrCardsTabs.qrCards, branchId }) =>
  `/dashboard/qr-cards/?tab=${tab}${
    tab === qrCardsTabs.qrCards && branchId ? `&branchId=${branchId}` : ''
  }`

export const urlsRoute = '/dashboard/shortened-urls/'
export const urlDetailsRoute = '/dashboard/shortened-urls/:id'

export const getURLDetailsRoute = (urlId) => {
  return `${urlsRoute}${urlId}`
}

export const staffRoute = '/branches/:id/staff/'
export const staffDetailsRoute = '/branches/staff/:id'

export const cardRoute = '/branches/:id/card'
export const cardDetailsRoute = '/dashboard/qr-cards/:uuid'

export const getCardDetailsRoute = (branchId, cardId, page = 0) => {
  const params = branchId ? `?page=${page}` : ''
  return `/dashboard/qr-cards/${cardId}/${params}`
}

export const subscriptionCardRoute = '/branches/:id/daily-key'

export const reviewRoute = '/branches/:branchId/review'
export const reviewDetailsRoute = '/branches/:branchId/review/:reviewId/'

export const dashboardReviewRoute = '/dashboard/:branchId/review'
export const dashboardReviewDetailsRoute = '/dashboard/:branchId/review/:reviewId/'

export const loyaltyRoute = '/dashboard/loyalty'
export const loyaltyTabs = {
  coupons: 'coupons',
  sprints: 'sprints',
  statics: 'statics',
  marketing: 'marketing',
  influencer: 'influencer',
  customer: 'customer',
  rewards: 'rewards',
}
export const getLoyaltyRoute = ({ tab, sprint, page = 0 }) =>
  `${loyaltyRoute}/?page=${page}&tab=${tab}${sprint ? `&sprint=${sprint}` : ''}`

// orders
export const orderRoute = '/dashboard/order'
export const orderTabs = {
  active: 'active',
  history: 'history',
}
export const getOrderRoute = ({ tab, page = 0 }) =>
  `${orderRoute}/?page=${page}&tab=${tab}`

export const privacyRoute = '/content/privacy'

export const notFoundRoute = '/errors/error-404'
export const unauthorizedRoute = '/errors/error-401'
export const serverErrorRoute = '/errors/error-500'

// dynamic routes
export const getEmailSentRoute = (email) =>
  `/auth/password/reset/email-sent/${email}`

export const getBranchDashboardRoute = (branchId) =>
  `/branches/dashboard/${branchId}?branchId=${branchId}&branchTab=0`

export const getStaffRoute = (branchId, status) =>
  `/branches/${branchId}/staff?status=${status}&branchId=${branchId}&branchTab=1`

export const getCardRoute = (branchId) =>
  `/branches/${branchId}/card?branchId=${branchId}&branchTab=0`

export const getReviewRoute = (branchId, filters) => {
  const { status, user, complaint } = filters
  let queryParams = ''
  if (status) queryParams = `status=${status}`
  if (user) queryParams = `${queryParams}&user=${user}`
  if (complaint !== undefined) queryParams = `${queryParams}&complaint=${complaint}`
  queryParams = `${queryParams}&branchId=${branchId}&branchTab=2`
  return `/branches/${branchId}/review?${queryParams}`
}

export const getReviewDetailsRoute = (branchId, reviewId, filters) => {
  const { status, user, complaint } = filters
  let queryParams = ''
  if (status) queryParams = `status=${status}`
  if (user) queryParams = `${queryParams}&user=${user}`
  if (complaint !== undefined) queryParams = `${queryParams}&complaint=${complaint}`
  queryParams = `${queryParams}&branchId=${branchId}&branchTab=3`
  return `/branches/${branchId}/review/${reviewId}?${queryParams}`
}

export const getDashboardReviewRoute = (branchId, filters) => {
  const { status, user, complaint } = filters
  let queryParams = ''
  if (status) queryParams = `status=${status}`
  if (user) queryParams = `${queryParams}&user=${user}`
  if (complaint !== undefined) queryParams = `${queryParams}&complaint=${complaint}`
  return `/dashboard/${branchId}/review?${queryParams}`
}

export const getDashboardReviewDetailsRoute = (branchId, reviewId, filters) => {
  const { status, user, complaint } = filters
  let queryParams = ''
  if (status) queryParams = `status=${status}`
  if (user) queryParams = `${queryParams}&user=${user}`
  if (complaint !== undefined) queryParams = `${queryParams}&complaint=${complaint}`
  return `/dashboard/${branchId}/review/${reviewId}?${queryParams}`
}

export const getSubscriptionCardRoute = (branchId) =>
  `/branches/${branchId}/daily-key?branchId=${branchId}&branchTab=3`

export const getMessageDetailsRoute = (page, messageId) =>
  `/dashboard/message/${page}/${messageId}`

export const getSurveyDetailsRoute = (surveyId) => `/dashboard/survey/${surveyId}`
export const getSurveyAnalyticsRoute = (surveyId, filters = '') =>
  `/dashboard/survey/${surveyId}/analytics/${filters}`

export const getProfileDetailsRoute = (step) => `/dashboard/profile/?step=${step}`

export const getBrandDetailsRoute = () => `/venue/brand/`
export const getDashboardBrandDetailsRoute = () => `/dashboard/venue/brand/`
export const getStaffDetailsRoute = (staffId, branchId) =>
  `/branches/staff/${staffId}?branchId=${branchId}&branchTab=2&user_id=${staffId}`

export const getCategoryRoute = (menuId) => `/dashboard/menu/${menuId}/category`
