import { BASE_URL } from 'src/Config'
import { getCurrentBrandId } from 'src/utils/auth'
import axiosInstance from './axios'

export const fetchAreas = (region = '') => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/areas/?region=${region}`
  )
}

export const fetchArea = (areaId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/areas/${areaId}/`)
}

export const addArea = (area) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/areas/`, area)
}

export const updateArea = (areaId, area) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/${brand}/areas/${areaId}/`,
    area
  )
}

export const deleteArea = (areaId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brand}/areas/${areaId}/`)
}
