import { BASE_URL } from 'src/Config'
import { getCurrentUser } from 'src/utils/auth'
import axiosInstance from './axios'

export const fetchQRCards = ({ brand, location }) => {
  const { user } = getCurrentUser()
  let params = ''
  const isManager = user.role === 'manager'

  if (location === 'unassigned') {
    params = '?has_branch=false'
  } else if (location) {
    params = `?has_branch=true&branch=${location}`
  } else if (isManager) {
    params = `?has_branch=true&branch=${user.location}`
  }

  const url = `${BASE_URL}/api/dashboards/${brand}qr-codes/v2/${params}`
  return axiosInstance.get(url)
}

export const retrieveQRCard = ({ brand, uuid }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}qr-codes/v2/${uuid}/`
  return axiosInstance.get(url)
}

export const createQRCard = ({ brand, location, users, menu, menu_v2, survey }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}qr-codes/v2/`
  const branch = location ?? ''
  return axiosInstance.post(url, {
    users,
    brand,
    location: branch,
    menu,
    menu_v2,
    survey,
  })
}

export const bulkCreateQRCard = ({
  brand,
  location,
  menu_v2,
  survey,
  count,
  auto_approve,
}) => {
  const url = `${BASE_URL}/api/dashboards/${brand}/branch/${location}/qr-codes/bulk/`
  return axiosInstance.post(url, {
    brand,
    location,
    menu_v2,
    survey,
    count,
    auto_approve,
  })
}

export const updateQRCard = ({
  brand,
  location,
  uuid,
  users,
  menu,
  menu_v2,
  survey,
  auto_approve,
  mode,
}) => {
  const url = `${BASE_URL}/api/dashboards/${brand}qr-codes/v2/${uuid}/`
  const branch = location ?? ''
  return axiosInstance.patch(url, {
    users,
    brand,
    location: branch,
    menu,
    menu_v2,
    survey,
    auto_approve,
    mode,
  })
}

export const deleteQRCard = ({ brand, uuid }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}qr-codes/v2/${uuid}/`
  return axiosInstance.delete(url)
}

export const fetchRequests = ({ brand, location, uuid, page = 1 }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}/${location}/${uuid}/qr-requests/?page=${page}&limit=5`
  return axiosInstance.get(url)
}

export const acceptRequest = ({ brand, location, id }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}/${location}/qr-requests/${id}/accept/`
  return axiosInstance.put(url)
}

export const declineRequest = ({ brand, location, id }) => {
  const url = `${BASE_URL}/api/dashboards/${brand}/${location}/qr-requests/${id}/decline/`
  return axiosInstance.delete(url)
}

export const joinQRCard = (uuid) => {
  const url = `${BASE_URL}/api/dashboards/qr-cards/${uuid}/qr-requests/request/`
  return axiosInstance.post(url)
}
