import {
  SET_CURRENT_USER,
  SESSION_LOGIN,
  SESSION_UPDATED,
  REFRESH_TOKEN,
} from 'src/store/actions/types'

const initialState = {
  loggedIn: false,
  access: null,
  refresh: null,
  user: {
    token: null,
    firstName: null,
    lastName: null,
    userId: null,
    brandId: null,
    role: null,
    location: null,
    photo: null,
    permissions: [],
  },
}

const sessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        access: payload.access,
        refresh: payload.refresh,
        user: {
          firstName: payload.user.first_name,
          lastName: payload.user.last_name,
          photo: payload.user.photo,
          userId: payload.user.id,
          brandId: payload.user.brand,
          role: payload.user.role,
          token: payload.key,
          location: payload.user.location,
          permissions: payload.user.permissions,
        },
      }
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        loggedIn: !!Object.keys(payload).length,
        access: payload.access,
        refresh: payload.refresh,
        user: {
          firstName: payload.user.first_name,
          lastName: payload.user.last_name,
          photo: payload.user.photo,
          userId: payload.user.id,
          brandId: payload.user.brand,
          role: payload.user.role,
          token: payload.key,
          location: payload.user.location,
          permissions: payload.user.permissions,
        },
      }
    }
    case SESSION_UPDATED:
      return { ...state, user: payload.user }
    case REFRESH_TOKEN:
      return { ...state, access: payload }
    default:
      return state
  }
}

export default sessionReducer
