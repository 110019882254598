import {
  CHANGE_REVIEWS_PAGE,
  CHANGE_REVIEWS_STATUS,
  FETCH_REVIEWS_LIST,
} from './types'

export const fetchReviewsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_REVIEWS_LIST, payload: data })
}

export const changeReviewsPage = (page) => (dispatch) => {
  dispatch({ type: CHANGE_REVIEWS_PAGE, payload: page })
}

export const changeReviewsStatus = (status) => (dispatch) => {
  dispatch({ type: CHANGE_REVIEWS_STATUS, payload: status })
}
