/* eslint-disable no-nested-ternary */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Slide } from '@material-ui/core'
import MUIModal from '@material-ui/core/Modal'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.only('lg')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '98%',
    },
    borderRadius: '14px',
    maxHeight: '96%',
  },
  paper: ({ minWidth }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '5px 50px 40px 50px',
    borderRadius: '14px',
    margin: '60px 0',
    minWidth: minWidth || 0,
  }),
}))

export const Modal = ({ open, children, handleClose, minWidth, maxWidth }) => {
  const classes = useStyles({ minWidth })

  const body = (
    <PerfectScrollbar>
      <div className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item xs={maxWidth || (minWidth ? 'auto' : 12)}>
            <Slide direction="up" in mountOnEnter unmountOnExit>
              <Paper className={classes.paper} elevation={20}>
                {children}
                <Modal />
              </Paper>
            </Slide>
          </Grid>
        </Grid>
      </div>
    </PerfectScrollbar>
  )

  return (
    <MUIModal open={open} onClose={handleClose}>
      {body}
    </MUIModal>
  )
}
