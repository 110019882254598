import {
  CREATE_MENU,
  FETCH_MENUS,
  DELETE_MENU,
  CREATE_DYNAMIC_MENU,
  FETCH_DYNAMIC_MENUS,
  DELETE_DYNAMIC_MENU,
  SELECT_DYNAMIC_MENU,
  UPDATE_DYNAMIC_MENU,
  FETCH_MENU_ITEMS,
  SELECT_MENU_ITEM,
  CREATE_MENU_ITEM,
  UPDATE_MENU_ITEM,
  DELETE_MENU_ITEM,
  SET_MODAL_STATUS,
  CREATE_MENU_GROUP,
  UPDATE_MENU_GROUPS,
} from '../actions/types'

const INITIAL_STATE = {
  menus: null,
  dynamicMenus: null,
  selectedDynamicMenu: null,
  items: null,
  selectedItem: null,
  modalStatus: { isItemModalOpen: false, isMenuModalOpen: false },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_MENU:
      return { ...state, menus: [payload, ...state.menus] }
    case FETCH_MENUS:
      return { ...state, menus: payload }
    case DELETE_MENU:
      return { ...state, menus: payload }
    case CREATE_DYNAMIC_MENU:
      return { ...state, dynamicMenus: [payload, ...state.dynamicMenus] }
    case FETCH_DYNAMIC_MENUS:
      return { ...state, dynamicMenus: payload }
    case UPDATE_DYNAMIC_MENU:
      return { ...state, dynamicMenus: payload }
    case DELETE_DYNAMIC_MENU:
      return { ...state, dynamicMenus: payload }
    case SELECT_DYNAMIC_MENU:
      return { ...state, selectedDynamicMenu: payload }
    case CREATE_MENU_GROUP:
      return {
        ...state,
        selectedDynamicMenu: {
          ...state.selectedDynamicMenu,
          menu_groups: [...state.selectedDynamicMenu.menu_groups, payload],
        },
      }
    case UPDATE_MENU_GROUPS:
      return {
        ...state,
        selectedDynamicMenu: { ...state.selectedDynamicMenu, menu_groups: payload },
      }
    case CREATE_MENU_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          count: state.items.count + 1,
          results: [...state.items.results, payload],
        },
      }
    case FETCH_MENU_ITEMS:
      return { ...state, items: payload }
    case UPDATE_MENU_ITEM:
      return {
        ...state,
        items: { ...state.items, results: payload },
      }
    case DELETE_MENU_ITEM:
      return {
        ...state,
        items: { ...state.items, count: state.items.count - 1, results: payload },
      }
    case SELECT_MENU_ITEM:
      return { ...state, selectedItem: payload }
    case SET_MODAL_STATUS:
      return { ...state, modalStatus: { ...state.modalStatus, ...payload } }
    default:
      return state
  }
}
