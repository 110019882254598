import { SET_FROM_DATE, SET_TO_DATE } from 'src/store/actions/types'
import moment from 'moment'

const urlParams = new URLSearchParams(window.location.search)
const from = urlParams.get('from_dt')
const to = urlParams.get('to_dt')

const INITIAL_STATE = {
  from: from || moment().subtract(30, 'days').format('YYYY-MM-DD'),
  to: to || moment().format('YYYY-MM-DD'),
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_FROM_DATE:
      return { ...state, from: payload }
    case SET_TO_DATE:
      return { ...state, to: payload }
    default:
      return state
  }
}
