import {
  FETCH_SHORTENED_URLS,
  DELETE_SHORTENED_URL,
  FETCH_SHORTENED_URL,
  UPDATE_SHORTENED_URL,
  SHORTEN_URL,
} from '../actions/types'

const INITIAL_STATE = {
  shortenedURLS: [],
  shortenedURL: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SHORTENED_URLS:
      return { ...state, shortenedURLS: payload }
    case FETCH_SHORTENED_URL:
      return { ...state, shortenedURL: payload }
    case DELETE_SHORTENED_URL:
      return { ...state, shortenedURLS: payload }
    case UPDATE_SHORTENED_URL:
      return { ...state, shortenedURL: payload }
    case SHORTEN_URL:
      return { ...state, shortenedURL: payload }

    default:
      return state
  }
}
