import { BASE_URL } from 'src/Config'
import { orderTabs } from 'src/constants'
import { getCurrentBrandId } from 'src/utils/auth'
import axiosInstance from './axios'

export const fetchOrders = ({ from, to }, page, query, tab) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/${brand}/orders/?page=${page}&from_dt=${from}&to_dt=${to}&search=${query}&finished=${Boolean(
      tab === orderTabs.history
    )}`
  )
}

export const retrieveOrder = (slug) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/order/${slug}`)
}

export const updateOrder = (order) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(
    `${BASE_URL}/api/dashboards/${brand}/order/${order.slug}/`,
    order
  )
}

export const confirmOrder = (slug) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(
    `${BASE_URL}/api/dashboards/${brand}/order/${slug}/confirm/`
  )
}

export const completeOrder = (slug) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(
    `${BASE_URL}/api/dashboards/${brand}/order/${slug}/finish/`
  )
}

export const createOrderItem = (slug, item) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/${brand}/order/${slug}/order-item/create/`,
    item
  )
}

export const updateOrderItem = (item) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(
    `${BASE_URL}/api/dashboards/${brand}/order/order-item/${item.id}/update/`,
    item
  )
}

export const deleteOrderItem = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${brand}/order/order-item/${id}/delete/`
  )
}
