export const deleteMenu = {
  title: 'Confirm delete menu ?',
  message: 'Are you sure you want to delete this menu ?',
  item: 'menu',
}

export const deleteCard = {
  title: 'Confirm delete card ?',
  message: 'Are you sure you want to delete this card ?',
  item: 'card',
}

export const deleteApprovedStaff = {
  title: 'Confirm delete staff ?',
  message: 'Are you sure you want to delete this staff ?',
  item: 'approvedStaff',
}

export const deletePendingStaff = {
  title: 'Confirm delete staff ?',
  message: 'Are you sure you want to delete this staff ?',
  item: 'pendingStaff',
}

export const deleteBranch = {
  title: 'Confirm delete branch ?',
  message: 'Are you sure you want to delete this branch ?',
  item: 'branch',
}

export const deleteCategory = {
  title: 'Confirm delete category ?',
  message: 'Are you sure you want to delete this category ?',
  item: 'menuCategory',
}

export const deleteMenuItem = {
  title: 'Confirm delete item ?',
  message: 'Are you sure you want to delete this item ?',
  item: 'menuItem',
}

export const deleteGroupItem = {
  title: 'Confirm delete item ?',
  message: 'Are you sure you want to delete this item ?',
  item: 'groupItem',
}

export const deleteDynamicMenu = {
  title: 'Confirm delete menu ?',
  message: 'Are you sure you want to delete this menu ?',
  item: 'dynamicMenu',
}

export const deleteOrder = {
  title: 'Confirm delete order ?',
  message: 'Are you sure you want to delete this order ?',
  item: 'order',
}

export const deleteOrderItem = {
  title: 'Confirm delete order item ?',
  message: 'Are you sure you want to delete this item ?',
  item: 'orderItem',
}

export const deleteStep = {
  title: 'Confirm delete step ?',
  message: 'Are you sure you want to delete this step ?',
  item: 'step',
}

export const deleteSurvey = {
  title: 'Confirm delete survey ?',
  message: 'Are you sure you want to delete this survey ?',
  item: 'survey',
}

export const removeSurveysFromFolder = {
  title: 'Confirm removing survey ?',
  message: 'Are you sure you want to remove this survey from folder ?',
  item: 'removeSurveyFromFolder',
}

export const deleteFolder = {
  title: 'Confirm delete folder ?',
  message: 'Are you sure you want to delete this folder ?',
  item: 'folder',
}

export const deleteRegion = {
  title: 'Confirm delete region ?',
  message: 'Are you sure you want to delete this region ?',
  item: 'region',
}

export const deleteArea = {
  title: 'Confirm delete area ?',
  message: 'Are you sure you want to delete this area ?',
  item: 'area',
}

export const deleteTemplate = {
  title: 'Confirm delete Qr Template ?',
  message: 'Are you sure you want to delete this qr template ?',
  item: 'qrTemplate',
}

export const deleteShortenedURL = {
  title: 'Confirm delete shortened URL ?',
  message: 'Are you sure you want to delete this shortened URL ?',
  item: 'shortenedURL',
}
