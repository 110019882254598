export const getCurrentUser = () => {
  return localStorage.user ? JSON.parse(localStorage.user) : { user: {} }
}

export const getUrl = (url, requestedValue, area, region) => {
  const { user } = getCurrentUser()
  const branchesUrl = `${url}/${user.brand}/location/list-access/`
  let params = ''

  switch (requestedValue) {
    case 'branches':
      if (user.role === 'owner') {
        if (area && region) params = `?region=${region}&area=${area}`
        else if (area) params = `?area=${area}`
        else if (region) params = `?region=${region}`
      }
      return branchesUrl + params
    case 'staff':
      return user.role === 'owner' ? url : url
    case 'stats':
      return user.role === 'owner' || user.role === 'manager'
        ? `${url}/staff`
        : `${url}/personal`
    default:
      return null
  }
}

export const getCookie = (cookieName) => {
  const name = `${cookieName}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return false
}

export const getAccessToken = () => {
  if (!localStorage.user) return false
  return JSON.parse(localStorage.user).access
}

export const getRefreshToken = () => {
  return JSON.parse(localStorage.user).refresh
}

export const setCurrentUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const setLocalAccessToken = (access) => {
  localStorage.setItem(
    'user',
    JSON.stringify({
      ...JSON.parse(localStorage.getItem('user')),
      access,
    })
  )
}

export const getCurrentBrandId = () => {
  if (localStorage?.user) return JSON.parse(localStorage?.user)?.user?.brand
  return false
}
