import {
  CREATE_COUPON,
  CREATE_SPRINT,
  FETCH_COUPONS,
  FETCH_FOLLOWERS,
  FETCH_REWARDS,
  FETCH_SPRINTS,
  RETRIEVE_SPRINT,
  UPDATE_SPRINT,
} from '../actions/types'

const INITIAL_STATE = {
  coupons: null,
  sprints: null,
  statics: null,
  rewards: null,
  followers: null,
  sprint: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CREATE_COUPON:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          count: state.coupons.count + 1,
          results: [...state.coupons.results, payload],
        },
      }
    case CREATE_SPRINT:
      return {
        ...state,
        sprints: {
          ...state.sprints,
          count: state.sprints.count + 1,
          results: [...state.sprints.results, payload],
        },
      }
    case UPDATE_SPRINT:
      return { ...state, sprints: payload }
    case FETCH_COUPONS:
      return { ...state, coupons: payload }
    case FETCH_FOLLOWERS:
      return { ...state, followers: payload }
    case FETCH_SPRINTS:
      return { ...state, sprints: payload }
    case FETCH_REWARDS:
      return { ...state, rewards: payload }
    case RETRIEVE_SPRINT:
      return { ...state, sprint: payload }
    default:
      return state
  }
}
