import { CLOSE_MODAL, OPEN_MODAL } from '../actions/types'

const INITIAL_STATE = { open: false, message: '', item: '' }

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL:
      return { ...payload, open: true }
    case CLOSE_MODAL:
      return { open: false, message: '', item: '' }
    default:
      return state
  }
}
