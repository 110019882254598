import React from 'react'

export const CustomerIcon = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M7.50001 8.34106C8.19183 8.34106 8.86814 8.10674 9.4435 7.6677C10.0189 7.22866 10.4675 6.6046 10.7327 5.87433C10.9978 5.14407 11.0677 4.34037 10.9334 3.56475C10.7992 2.78913 10.4668 2.07638 9.97827 1.51653C9.48975 0.956686 8.86701 0.574856 8.18871 0.419273C7.51042 0.26369 6.80699 0.341333 6.16728 0.642395C5.52757 0.943456 4.98028 1.45443 4.59454 2.11078C4.2088 2.76712 4.00191 3.53939 4 4.33005C4 5.39193 4.36844 6.41048 5.02454 7.16238C5.68064 7.91427 6.57086 8.33815 7.50001 8.34106Z"
        fill="#79869F"
      />
      <path
        opacity="0.7"
        d="M14.9109 14.3805C14.3213 12.8935 13.3002 11.6183 11.98 10.7198C10.6597 9.82138 9.10093 9.34106 7.50545 9.34106C5.90998 9.34106 4.35123 9.82138 3.03094 10.7198C1.71066 11.6183 0.689627 12.8935 0.0999895 14.3805C0.0139472 14.5965 -0.0172861 14.8305 0.00909211 15.0616C0.0354703 15.2926 0.118635 15.5135 0.251122 15.7044C0.382842 15.9017 0.561335 16.0632 0.770593 16.1743C0.979851 16.2854 1.21333 16.3427 1.4501 16.341H13.5407C13.7771 16.3409 14.0099 16.2829 14.2189 16.1719C14.4279 16.061 14.6066 15.9004 14.7396 15.7044C14.873 15.5141 14.9583 15.2942 14.9881 15.0636C15.0179 14.833 14.9915 14.5986 14.9109 14.3805Z"
        fill="#79869F"
      />
    </svg>
  )
}
