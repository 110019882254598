import React from 'react'

export const SurvLogoIcon = () => {
  return (
    <svg
      width="109"
      height="43"
      viewBox="0 0 109 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_492_1256)">
        <path
          d="M107.372 5.15741L100.317 7.48509V0H94.845V7.48509L87.7449 4.92921L86.0264 10.0866L93.2169 12.5969L88.6946 18.5302L93.036 21.8619L97.4227 15.883L101.945 22.1358L106.422 18.804L101.945 12.5969L109 10.3148L107.372 5.15741Z"
          fill="white"
        />
        <path
          d="M17.2412 31.6747C17.2412 35.6911 14.2113 38.5209 8.37741 38.5209C2.58879 38.5209 -0.893433 35.326 -0.893433 35.326L1.00596 31.4922C3.49326 33.3178 6.2519 34.3219 8.33219 34.3219C10.4125 34.3219 11.6787 33.5917 11.6787 32.1312C11.6787 30.6706 10.7743 30.2142 7.60861 29.1189C4.44296 28.0691 0.237158 26.5173 0.237158 22.2271C0.237158 17.8912 4.03594 15.381 8.7392 15.381C13.4425 15.381 16.9699 17.7999 16.9699 17.7999L14.9801 21.7707C12.7189 20.1733 9.37233 19.7169 8.37741 19.7169C7.38249 19.7169 5.66399 19.8994 5.66399 21.7251C5.66399 23.5507 7.38249 23.6876 10.8195 24.9655C14.3017 26.1978 17.2412 27.6584 17.2412 31.6747Z"
          fill="white"
        />
        <path
          d="M42.3856 15.6548V38.3383H36.7327V34.2306C35.5116 37.0147 33.2957 38.5665 29.723 38.5665C23.8439 38.5665 20.4974 34.2762 20.4974 27.2475V15.7004H26.1503V27.2475C26.1503 31.127 28.1402 33.5916 31.4415 33.5916C34.7428 33.5916 36.7327 31.127 36.7327 27.2475V15.6548H42.3856Z"
          fill="white"
        />
        <path
          d="M61.063 15.4723V20.6297H59.3445C55.3196 20.6297 52.7418 23.003 52.6061 26.7456V38.2927H47.0436V15.6549H52.6514V20.4471C54.0533 17.2523 56.8119 15.4723 61.063 15.4723Z"
          fill="white"
        />
        <path
          d="M85.6194 15.6548L77.1173 38.3383H71.3287L62.8267 15.6548H68.4344L74.223 32.4962L80.0116 15.6548H85.6194Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_492_1256">
          <rect width="109" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
