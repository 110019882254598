import { getCurrentBrandId, getUrl } from 'src/utils/auth'
import { BASE_URL } from 'src/Config'
import store from 'src/store'
import qs from 'qs'
import axiosInstance from './axios'

export const postDailyKey = (userId, dailyKey) => {
  return axiosInstance({
    method: 'post',
    url: `${BASE_URL}/api/venue/staff/`,
    data: {
      user: userId,
      daily_key: dailyKey,
    },
  })
}

export const fetchMenus = (brandId) => {
  const branchId = store.getState().branch.selectedBranch.id
  const filter = branchId ? `?branch=${branchId}` : ''
  const url = `${BASE_URL}/api/dashboards/${brandId}/menus/${filter}`
  return axiosInstance.get(url)
}

export const createMenu = (brand, data) => {
  return axiosInstance({
    method: 'post',
    url: `${BASE_URL}/api/dashboards/${brand}/menus/`,
    data: { brand, ...data },
  })
}

export const deleteMenu = (brandId, uuid) => {
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brandId}/menus/${uuid}`)
}

export const fetchBrandApprovedStaffList = (branchId) => {
  const brandId = getCurrentBrandId()
  const filter = branchId ? `&location=${branchId}` : ''
  const url = `${BASE_URL}/api/venue/staff/?brand=${brandId}&approved=true${filter}`
  return axiosInstance.get(url)
}

export const getApprovedStaffList = (brandId, location) => {
  const url = getUrl(
    `${BASE_URL}/api/dashboards/${brandId}/branch/${location}/staff/approved/`,
    'staff'
  )
  return axiosInstance.get(url)
}

export const fetchPendingStaff = (brandId, location) => {
  const url = `${BASE_URL}/api/dashboards/${brandId}/branch/${location}/staff/pending/`
  return axiosInstance.get(url)
}

export const deleteApprovedStaff = (brand, location, staffId) => {
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${brand}/branch/${location}/staff/approved/${staffId}/`
  )
}

export const deletePendingStaff = (brand, location, staffId) => {
  return axiosInstance.delete(
    `${BASE_URL}/api/dashboards/${brand}/branch/${location}/staff/pending/${staffId}/`
  )
}

export const approveStaff = (brand, location, staffId) => {
  return axiosInstance.post(
    `${BASE_URL}/api/dashboards/${brand}/branch/${location}/staff/pending/${staffId}/approve/`
  )
}

export const changeLocation = (staffId, locationId) => {
  return axiosInstance.patch(`${BASE_URL}/api/venue/staff/${staffId}/`, {
    location: locationId,
  })
}

export const fetchBrand = (brandId) => {
  return axiosInstance.get(`${BASE_URL}/api/venue/${brandId}`)
}

export const fetchBranch = (branchId) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/venue/${brandId}/locations/${branchId}`)
}

export const fetchDashboardStats = ({ from, to, regionId, areaId, branchId }) => {
  let url = ''
  const brandId = getCurrentBrandId()
  if (regionId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/overall-analytics/?from_dt=${from}&to_dt=${to}&region=${regionId}`
  } else if (areaId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/overall-analytics/?from_dt=${from}&to_dt=${to}&area=${areaId}`
  } else if (branchId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/overall-analytics/?from_dt=${from}&to_dt=${to}&branch=${branchId}`
  } else {
    url = `${BASE_URL}/api/dashboards/${brandId}/overall-analytics/?from_dt=${from}&to_dt=${to}`
  }
  return axiosInstance.get(url)
}
export const fetchRegionsPerformance = ({ from, to }) => {
  const brand = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/${brand}/region-performance/?from_dt=${from}&to_dt=${to}`
  return axiosInstance.get(url)
}
export const fetchAreasPerformance = ({ from, to, regionId }) => {
  const brand = getCurrentBrandId()
  const filter = `${regionId ? `&region=${regionId}` : ''}`
  const url = `${BASE_URL}/api/dashboards/${brand}/area-performance/?from_dt=${from}&to_dt=${to}${filter}`
  return axiosInstance.get(url)
}
export const fetchBranchesPerformance = ({ from, to, regionId, areaId }) => {
  const brand = getCurrentBrandId()
  const filter = `${regionId ? `&region=${regionId}` : ''}${
    areaId ? `&area=${areaId}` : ''
  }`
  const url = `${BASE_URL}/api/dashboards/${brand}/branch-performance/?from_dt=${from}&to_dt=${to}${filter}`
  return axiosInstance.get(url)
}
export const fetchStaffPerformance = ({ from, to, regionId, areaId, branchId }) => {
  const brand = getCurrentBrandId()
  const filter = `${regionId ? `&region=${regionId}` : ''}${
    areaId ? `&area=${areaId}` : ''
  }${branchId ? `&branch=${branchId}` : ''}`
  const url = `${BASE_URL}/api/dashboards/${brand}/staff-performance/?from_dt=${from}&to_dt=${to}${filter}`
  return axiosInstance.get(url)
}

export const fetchTrendData = ({
  // trend type is either performance , response or nps
  trendType,
  from,
  to,
  regionId,
  areaId,
  branchId,
  staffId,
}) => {
  let url = ''
  const brandId = getCurrentBrandId()
  if (regionId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/${trendType}-trend/?from_dt=${from}&to_dt=${to}&region=${regionId}`
  } else if (areaId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/${trendType}-trend/?from_dt=${from}&to_dt=${to}&area=${areaId}`
  } else if (branchId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/${trendType}-trend/?from_dt=${from}&to_dt=${to}&branch=${branchId}`
  } else if (staffId) {
    url = `${BASE_URL}/api/dashboards/${brandId}/${trendType}-trend/?from_dt=${from}&to_dt=${to}&staff=${staffId}`
  } else {
    url = `${BASE_URL}/api/dashboards/${brandId}/${trendType}-trend/?from_dt=${from}&to_dt=${to}`
  }
  return axiosInstance.get(url)
}

export const fetchMultipleChoices = (brandId, startDate, endDate, page, filter) => {
  const url = `${BASE_URL}/api/dashboards/${brandId}/multi-select?from_dt=${startDate}&to_dt=${endDate}&page=${page}&limit=5&${filter}`
  return axiosInstance.get(url)
}

export const fetchDashBoardOfBranch = (branchId, startDate, endDate, selected) => {
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/branch/${branchId}?from_dt=${startDate}&to_dt=${endDate}&selected_category=${
      selected || 2
    }`
  )
}

export const fetchBranches = (area, region) => {
  const url = getUrl(`${BASE_URL}/api/dashboards`, 'branches', area, region)
  return axiosInstance.get(url)
}

export const createBrand = (brand) => {
  const url = `${BASE_URL}/api/dashboards/brand/create/`
  return axiosInstance.post(url, brand)
}

export const updateBrand = (brand) => {
  const url = `${BASE_URL}/api/venue/${brand.id}`
  return axiosInstance.patch(url, brand)
}

export const createBrandCover = (cover) => {
  const url = `${BASE_URL}/api/dashboards/${cover.brand}/coverphoto/`
  return axiosInstance.post(url, cover)
}

export const updateBrandCover = (cover) => {
  const url = `${BASE_URL}/api/dashboards/${cover.brand}/coverphoto/${cover.id}/`
  return axiosInstance.put(url, cover)
}

export const fetchIndustries = () => {
  const url = `${BASE_URL}/api/venue/industries`
  return axiosInstance.get(url)
}

export const fetchStaff = () => {
  const url = `${BASE_URL}/api/dashboards/staff/brand-status`
  return axiosInstance.get(url)
}

export const fetchPersonalData = () => {
  const brand = getCurrentBrandId()
  if (!brand) return false
  const url = `${BASE_URL}/api/dashboards/${brand}/personal-data/`
  return axiosInstance.get(url)
}
export const fetchComplaintsAnalytics = ({ from, to }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/complaints/${brand}/analytics/?from_dt=${from}&to_dt=${to}`
  )
}

export const fetchFoldersSatisfactionAnalytics = ({ from, to, folders }) => {
  const brand = getCurrentBrandId()

  if (!brand) return false
  const url = `${BASE_URL}/api/dashboards/${brand}/folders/customer-satisfaction/?from_dt=${from}&to_dt=${to}`
  return axiosInstance.get(url, {
    params: { folders: folders },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const fetchFoldersNPSAnalytics = ({ from, to, folders }) => {
  const brand = getCurrentBrandId()

  if (!brand) return false
  const url = `${BASE_URL}/api/dashboards/${brand}/folders/nps-score/?from_dt=${from}&to_dt=${to}`
  return axiosInstance.get(url, {
    params: { folders: folders },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const fetchFoldersReviewsCount = ({ from, to, folders }) => {
  const brand = getCurrentBrandId()

  if (!brand) return false
  const url = `${BASE_URL}/api/dashboards/${brand}/folders/responses-count/?from_dt=${from}&to_dt=${to}`
  return axiosInstance.get(url, {
    params: { folders: folders },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

export const fetchFolderTrendData = ({
  // trend type is either performance , response or nps
  trendType,
  from,
  to,
  folders,
}) => {
  const brandId = getCurrentBrandId()

  if (!brandId) return false
  const url = `${BASE_URL}/api/dashboards/${brandId}/folders/${trendType}-trend/?from_dt=${from}&to_dt=${to}`

  return axiosInstance.get(url, {
    params: { folders: folders },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}
