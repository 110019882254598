import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { getAccessToken } from 'src/utils/auth'
import {
  handleResponse,
  handleError,
  handleRequestPendingStatus,
  handleResponsePendingStatus,
  resetPendingStatus,
  handleUnAuthorizedError,
} from 'src/utils/axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  function (config) {
    handleRequestPendingStatus(config)
    if (getAccessToken()) {
      const token = jwt_decode(getAccessToken())
      const isExpired = Date.now() >= token.exp * 1000
      if (!isExpired || !token || config?.url?.includes('/jwt/refresh/'))
        return config
      return handleUnAuthorizedError(config)
    }
    return config
  },
  function (error) {
    resetPendingStatus()
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    handleResponsePendingStatus()
    handleResponse(response)
    return response
  },
  function (error) {
    resetPendingStatus()
    handleError(error)
    return Promise.reject(error)
  }
)

export default axiosInstance
