import {
  FETCH_AREAS,
  DELETE_AREA,
  FETCH_AREA,
  UPDATE_AREA,
  ADD_AREA,
  SELECT_AREA,
  VIEW_AREA_BRANCHES,
  GET_AREA_DASHBOARD,
  FETCH_AREA_PERFORMANCE_TREND,
  FETCH_AREA_RESPONSE_TREND,
  FETCH_AREA_NPS_TREND,
  FETCH_AREA_BRANCHES_PERFORMANCE,
  FETCH_AREA_STAFF_PERFORMANCE,
} from '../actions/types'

const INITIAL_STATE = {
  areas: [],
  area: null,
  selectedArea: { name: '', region_name: '' },
  areaBranches: '',
  dashboard: {
    score: -1,
    customer_satisfaction: null,
    nps_score: null,
    branch_performance: [],
    staff_performance: [],
  },
  performanceTrend: [],
  responseTrend: [],
  npsTrend: [],
  branchesPerformance: [],
  staffPerformance: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_AREA_DASHBOARD:
      return { ...state, dashboard: { ...payload } }
    case FETCH_AREAS:
      return { ...state, areas: payload, selectedArea: payload[0] || {} }
    case FETCH_AREA:
      return { ...state, area: payload }
    case FETCH_AREA_PERFORMANCE_TREND:
      return { ...state, performanceTrend: payload }
    case FETCH_AREA_RESPONSE_TREND:
      return { ...state, responseTrend: payload }
    case FETCH_AREA_NPS_TREND:
      return { ...state, npsTrend: payload }
    case DELETE_AREA:
      return { ...state, areas: payload }
    case UPDATE_AREA:
      return { ...state, areas: payload }
    case ADD_AREA:
      return { ...state, areas: payload }
    case SELECT_AREA:
      return { ...state, selectedArea: payload }
    case VIEW_AREA_BRANCHES:
      return { ...state, areaBranches: payload }
    case FETCH_AREA_BRANCHES_PERFORMANCE:
      return { ...state, branchesPerformance: payload }
    case FETCH_AREA_STAFF_PERFORMANCE:
      return { ...state, staffPerformance: payload }

    default:
      return state
  }
}
