import React, { Suspense, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'
import { composeRoutes } from 'src/utils/routeConfig'
import {
  fetchBrandAction,
  fetchUnreadCountAction,
  setStaffDetailsAction,
} from 'src/store/actions'
import { fetchBrand, fetchStaff, fetchUnreadCount } from 'src/API'
import AuthGuard from 'src/components/Singles/AuthGuard'
import CustomizedSnackbar from 'src/components/Singles/CustomizedToast'
import Modal from 'src/components/Singles/Dialog'
import useQueryParams from 'src/Hooks/useQueryParams'
import NavBar from './NavBar'
import TopBar from './TopBar'
import { navConfig } from '../navConfig'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56,
    },
  },
}))

function Dashboard({
  children,
  route,
  session,
  brand,
  staff,
  brandId,
  role,
  messagesCount,
  loggedIn,
  fetchBrandAction,
  fetchUnreadCountAction,
  setStaffDetailsAction,
}) {
  const classes = useStyles()
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const { mobile } = useQueryParams()

  const fetchData = useCallback(async () => {
    if (brandId) {
      const { data } = await fetchBrand(brandId)
      fetchBrandAction(data)
    }
    if (loggedIn) {
      const { data } = await fetchUnreadCount()
      fetchUnreadCountAction(data)
      if (!staff) {
        const { data: staff } = await fetchStaff()
        setStaffDetailsAction(staff)
      }
    }
  }, [
    brandId,
    staff,
    fetchBrandAction,
    fetchUnreadCountAction,
    setStaffDetailsAction,
    loggedIn,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData, loggedIn])

  return (
    <>
      {!mobile && (
        <TopBar
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          messagesCount={messagesCount}
          role={session.user.role}
          staff={staff}
        />
      )}
      <NavBar
        brand={brand}
        role={role}
        navConfig={navConfig}
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />
      <div className={classes.container}>
        <div
          className={classes.content}
          style={{ paddingTop: mobile ? '10px' : '' }}
        >
          {children}
          <Suspense fallback={<LinearProgress />}>
            <AuthGuard route={route}>
              {renderRoutes(composeRoutes(route.routes, session))}
              <CustomizedSnackbar />
              <Modal />
            </AuthGuard>
          </Suspense>
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = {
  route: PropTypes.object,
}
const mapStateToProps = ({ session, branch, messages, venues }) => ({
  session,
  branch,
  messagesCount: messages?.count?.count,
  loggedIn: session.loggedIn,
  brandId: venues?.staffDetails?.brand,
  brand: venues?.venueBrand,
  staff: venues.staffDetails,
  role: session.user.role,
})

export default connect(mapStateToProps, {
  fetchUnreadCountAction,
  fetchBrandAction,
  setStaffDetailsAction,
})(Dashboard)
