import { combineReducers } from 'redux'
import session from './sessionReducer'
import branch from './branchReducer'
import brand from './brandReducer'
import profile from './profileReducer'
import survey from './surveyReducer'
import dateRange from './dateRangeReducer'
import venues from './venuReducer'
import users from './userReducer'
import qrcards from './qrCardReducer'
import menus from './menuReducer'
import orders from './orderReducer '
import messages from './inboxReducer'
import reviews from './reviewReducers'
import loyalty from './loyaltyReducer'
import toast from './toastReducer'
import modal from './modalReducer'
import pending from './pendingReducer'
import query from './filterReducer'
import region from './regionReducer'
import area from './areaReducer'
import error from './errorReducer'
import qrTemplate from './qrTemplateReducer'
import shortenedURL from './shortenedURLReducer'

const appReducer = combineReducers({
  session,
  branch,
  brand,
  profile,
  dateRange,
  survey,
  venues,
  users,
  qrcards,
  qrTemplate,
  shortenedURL,
  menus,
  orders,
  messages,
  reviews,
  loyalty,
  toast,
  modal,
  pending,
  query,
  area,
  region,
  error,
})

const rootReducer = (state, action) => {
  if (action.type === 'SESSION_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
