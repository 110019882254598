import {
  FETCH_PENDING_STAFF,
  FETCH_APPROVED_STAFF,
  DELETE_PENDING_STAFF,
  APPROVE_STAFF,
  POST_DAILY_KEY,
  DELETE_APPROVED_STAFF,
  FETCH_VENUE_BRAND,
  FETCH_BRANCHES,
  FETCH_BRANCH,
  GET_BRAND_DASHBOARD,
  FETCH_BRAND_PERFORMANCE_TREND,
  FETCH_BRAND_RESPONSE_TREND,
  FETCH_BRAND_NPS_TREND,
  FETCH_MENUS,
  CREATE_MENU,
  DELETE_MENU,
  UPDATE_VENUE_BRAND,
  DELETE_BRANCH,
  SELECT_BRANCH,
  ADD_BRANCH,
  UPDATE_BRANCH,
  FETCH_PERSONAL_DATA,
  GET_REGION_DASHBOARD,
  GET_AREA_DASHBOARD,
  GET_BRANCH_DASHBOARD,
  FETCH_BRANCH_PERFORMANCE_TREND,
  FETCH_BRANCH_RESPONSE_TREND,
  FETCH_BRANCH_NPS_TREND,
  FETCH_COMPLAINTS_ANALYTICS,
  FETCH_REGION_PERFORMANCE_TREND,
  FETCH_REGION_RESPONSE_TREND,
  FETCH_REGION_NPS_TREND,
  FETCH_BRAND_REGIONS_PERFORMANCE,
  FETCH_BRAND_AREAS_PERFORMANCE,
  FETCH_BRAND_BRANCHES_PERFORMANCE,
  FETCH_BRAND_STAFF_PERFORMANCE,
  FETCH_REGION_AREAS_PERFORMANCE,
  FETCH_REGION_BRANCHES_PERFORMANCE,
  FETCH_REGION_STAFF_PERFORMANCE,
  FETCH_BRANCH_STAFF_PERFORMANCE,
} from 'src/store/actions/types'

export const fetchApprovedStaffAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_APPROVED_STAFF, payload: data })
}

export const fetchPendingStaffAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_PENDING_STAFF, payload: data })
}

export const deleteApprovedStaffAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_APPROVED_STAFF, payload: data })
}

export const deletePendingStaffAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_PENDING_STAFF, payload: data })
}

export const approveStaffAction = ({ pending, approved }) => (dispatch) => {
  dispatch({ type: APPROVE_STAFF, payload: { pending, approved } })
}

// export const updateStaff = (staffId, locationId, approvedStaff) =>  (
//   dispatch
// ) => {
//   const { data: staff } = await changeLocation(staffId, locationId)
//   dispatch({
//     type: UPDATE_STAFF,
//     payload: { staff, approvedStaff, staffId },
//   })
// }

export const postDailyKeyAction = (data) => (dispatch) => {
  dispatch({ type: POST_DAILY_KEY, payload: data })
}

export const fetchBrandAction = (data) => (dispatch) => {
  let emptyCovers = []
  if (data.cover_photos.length < 5)
    emptyCovers = new Array(5 - data.cover_photos.length)
  for (let index = 0; index < emptyCovers.length; index++) {
    emptyCovers[index] = {
      cover_photo: '',
    }
  }
  const brand = { ...data, cover_photos: [...data.cover_photos, ...emptyCovers] }
  dispatch({ type: FETCH_VENUE_BRAND, payload: brand })
}

export const updateBrandAction = (data) => (dispatch) => {
  dispatch({ type: UPDATE_VENUE_BRAND, payload: data })
}

export const fetchBranchesAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_BRANCHES, payload: data })
}
export const fetchBranchPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_BRANCH_PERFORMANCE_TREND, payload: data })
}
export const fetchBranchResponseTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_BRANCH_RESPONSE_TREND, payload: data })
}
export const fetchBranchNPSTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_BRANCH_NPS_TREND, payload: data })
}

export const getBranchAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_BRANCH, payload: data })
}

export const selectBranchAction = (branch) => (dispatch) => {
  dispatch({ type: SELECT_BRANCH, payload: branch })
}

export const fetchBrandDashboardAction = (data) => (dispatch) => {
  dispatch({
    type: GET_BRAND_DASHBOARD,
    payload: data,
  })
}
export const fetchBrandRegionsPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_REGIONS_PERFORMANCE,
    payload: data,
  })
}
export const fetchBrandAreasPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_AREAS_PERFORMANCE,
    payload: data,
  })
}
export const fetchBrandBranchesPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_BRANCHES_PERFORMANCE,
    payload: data,
  })
}
export const fetchBrandStaffPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_STAFF_PERFORMANCE,
    payload: data,
  })
}
export const fetchBrandPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_PERFORMANCE_TREND,
    payload: data,
  })
}
export const fetchBrandResponseTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_RESPONSE_TREND,
    payload: data,
  })
}
export const fetchBrandNPSTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRAND_NPS_TREND,
    payload: data,
  })
}
export const fetchComplaintsAnalyticsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_COMPLAINTS_ANALYTICS, payload: data })
}

export const fetchBranchDashboardAction = (data) => (dispatch) => {
  dispatch({
    type: GET_BRANCH_DASHBOARD,
    payload: data,
  })
}

export const fetchBranchStaffPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_BRANCH_STAFF_PERFORMANCE,
    payload: data,
  })
}

export const fetchRegionDashboardAction = (payload) => (dispatch) => {
  dispatch({
    type: GET_REGION_DASHBOARD,
    payload: payload,
  })
}
export const fetchRegionAreasPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_AREAS_PERFORMANCE,
    payload: data,
  })
}
export const fetchRegionBranchesPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_BRANCHES_PERFORMANCE,
    payload: data,
  })
}
export const fetchRegionStaffPerformanceAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_STAFF_PERFORMANCE,
    payload: data,
  })
}
export const fetchRegionPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_PERFORMANCE_TREND,
    payload: data,
  })
}
export const fetchRegionResponseTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_RESPONSE_TREND,
    payload: data,
  })
}
export const fetchRegionNPSTrendAction = (data) => (dispatch) => {
  dispatch({
    type: FETCH_REGION_NPS_TREND,
    payload: data,
  })
}

export const fetchAreaDashboardAction = (payload) => (dispatch) => {
  dispatch({
    type: GET_AREA_DASHBOARD,
    payload: payload,
  })
}

export const addBranchAction = (payload) => (dispatch) => {
  dispatch({ type: ADD_BRANCH, payload: payload })
}

export const updateBranchesAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_BRANCH, payload: payload })
}

export const deleteBranchAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_BRANCH, payload: payload })
}

export const fetchMenusAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_MENUS, payload: data })
}

export const createMenuAction = (data) => (dispatch) => {
  dispatch({ type: CREATE_MENU, payload: data })
}

export const deleteMenuAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_MENU, payload: data })
}

export const fetchPersonalDataAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_PERSONAL_DATA, payload: data })
}
