import {
  FETCH_VENUE_INDUSTRIES,
  FETCH_STAFF,
  FETCH_VENUE_LOCATIONS,
  FETCH_PENDING_STAFF,
  FETCH_APPROVED_STAFF,
  DELETE_PENDING_STAFF,
  APPROVE_STAFF,
  UPDATE_STAFF,
  DELETE_APPROVED_STAFF,
  POST_DAILY_KEY,
  FETCH_VENUE_BRAND,
  RESET_APPROVED_STAFF,
  UPDATE_VENUE_BRAND,
  FETCH_STAFF_DETAILS,
  FETCH_PERSONAL_DATA,
} from '../actions/types'

const INITIAL_STATE = {
  industries: [],
  staff: [],
  locations: [],
  staffPerson: '',
  location: '',
  approvedStaff: [],
  pendingStaff: [],
  dailyKeyData: {},
  staffApproved: null,
  editedStaffUsers: [],
  venueBrand: { covers_photo: [{}, {}, {}, {}, {}] },
  staffDetails: null,
  personalData: { brand: {} },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_VENUE_INDUSTRIES:
      return { ...state, industries: payload }
    case FETCH_STAFF:
      return { ...state, staff: payload }
    case FETCH_STAFF_DETAILS:
      return { ...state, staffDetails: payload }
    case FETCH_VENUE_LOCATIONS:
      return { ...state, locations: Array.isArray(payload) ? payload : [payload] }
    case RESET_APPROVED_STAFF:
      return { ...state, staffApproved: payload }
    case FETCH_APPROVED_STAFF:
      return { ...state, approvedStaff: payload, selected_staff: payload?.[0] || {} }
    case FETCH_PENDING_STAFF:
      return { ...state, pendingStaff: payload }
    case POST_DAILY_KEY:
      return { ...state, dailyKeyData: payload }
    case APPROVE_STAFF:
      return {
        ...state,
        pendingStaff: payload.pending,
        approvedStaff: payload.approved,
      }
    case DELETE_PENDING_STAFF:
      return { ...state, pendingStaff: payload }
    case DELETE_APPROVED_STAFF:
      return { ...state, approvedStaff: payload }
    case UPDATE_STAFF: {
      const { staff, approvedStaff, staffId } = payload
      const data = approvedStaff.map((item) =>
        item.staff_id === staffId
          ? {
              ...item,
              location: staff.location,
              location_name: staff.location_name,
            }
          : item
      )
      return { ...state, approvedStaff: data }
    }
    case FETCH_VENUE_BRAND:
      return { ...state, venueBrand: payload }
    case UPDATE_VENUE_BRAND:
      return { ...state, venueBrand: payload }
    case FETCH_PERSONAL_DATA:
      return { ...state, personalData: payload }
    default:
      return state
  }
}
