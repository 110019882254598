import {
  SHORTEN_URL,
  UPDATE_SHORTENED_URL,
  DELETE_SHORTENED_URL,
  FETCH_SHORTENED_URLS,
  FETCH_SHORTENED_URL,
} from './types'

export const shortenURLAction = (payload) => (dispatch) => {
  dispatch({ type: SHORTEN_URL, payload: payload })
}

export const updateShortenedURLAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SHORTENED_URL, payload: payload })
}

export const deleteShortenedURLAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_SHORTENED_URL, payload: payload })
}

export const fetchShortenedURLsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_SHORTENED_URLS, payload: data })
}

export const fetchShortenedURLAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_SHORTENED_URL, payload: data })
}
